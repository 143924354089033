.defaultCard {
  background-repeat: no-repeat;
  background-size: cover;
  width: 95% !important;
  padding: 10px;
  border-radius: 20px;
}
.LeverpayCardLogo {
  display: block;
  margin-left: auto;
}
.card_one {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.cardnumber {
  text-align: left;
  margin-left: 1rem;
  font-family: Kredit;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.1em;
  /* color: white; */
}
.cardDate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cardDate small {
  margin-bottom: -1.5rem;
  font-family: Consolas;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  /* color: white; */
}
.cardDate_Type {
  display: flex;
  gap: 6rem;
  padding: 0px 20px;
}
.defaultCard strong {
  font-family: Consolas;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.02em;
}
.defaultCard footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-top: 1rem;
}
.defaultCard footer h2 {
  font-family: Consolas;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-top: 1rem;
}
.defaultCard footer h1 {
  font-family: Consolas;
  font-size: 25px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.02em;
}
.defaultCard .cardcategory{
    font-family: Consolas;
font-size: 20px;
font-weight: 700;
line-height: 35px;
letter-spacing: 0.02em;
}
.defaultCard .card_scan{
  padding: 10px;
}
.defaultCard #card-cardname{
  font-size: 18px;
}

@media (max-width:1024px) and (min-width: 621px){

  .defaultCard .cardnumber{
    font-size: 14px;
    margin-bottom: -25px;
  }
  .defaultCard .LeverpayCardLogo{
    width: 100px;
  }
  .defaultCard .expiry{
    display: none;
  }
  .defaultCard .card_scan{
    width: 30px;
    height: 30px;
    object-fit: cover;
    padding: 0;
  }
  .defaultCard .card_lastname{
    display: none;
  }
  .defaultCard .cardDate_Type strong{
    font-size: 16px;
    margin-top: 1rem;
    margin-left: -2rem;
  }
  .defaultCard .exp_date{
    font-size: 12px;
    width: 70px;
  }
  .defaultCard .cvv{
    font-size: 10px;
    margin-top: -1rem;
  }
  .cvv small, .cvv strong{
    font-size: 14px;
  }
  .defaultCard footer h1{
    font-size: 15px;
  }
  .defaultCard footer h1 img{
    width: 12px;
    padding-right: 3px;
  }
}
@media (max-width:620px){
  .defaultCard{
    width: 100% !important;
  }
}