.login {
  background: url("../../assets/images/login-bcg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100svh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin-container > * {
  color: #fff;
}

p {
  margin: 0;
}

.forgot-password-link {
  margin-top: 8px;
  text-align: right !important;
  cursor: pointer;
}

.signin-container {
  display: flex;
  position: relative;
  max-height: 70vh;
  max-width: 700px;
  margin: auto;
}

.signin-container > * {
  animation: fadeinPg 3s ease 1s 1 normal none;
}

.image-container {
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
  border-radius: 10px 0 0 10px;
}

.signin-logo {
  position: relative;
  width: 120px;
  height: 20px;
  margin: 32px;
}

.mobile-logo {
  display: none;
}

.image-bg {
  object-position: center;
  height: 80%;
}

.img {
  object-fit: contain;
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.bg-text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
  margin-right: 35px;
  bottom: 50px;
  width: 90%;
  height: 130px;
  background-color: #0b0230;
  background: linear-gradient(
    90deg,
    rgba(23, 13, 94, 0.3) 50%,
    rgba(23, 13, 94, 0) 100%
  );
  backdrop-filter: blur(8px);
  border-radius: 15px;
  color: #fff;
}

.bg-text p {
  z-index: 100;
  text-align: center;
  font-family: "Montserrat" !important;
  color: #0b0230;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.bg-text p span {
  color: #0b0230;
  font-size: 16px;
  font-weight: 400 !important;
}

.chat-contain {
  position: absolute;
  bottom: 10px;
  right: 30px;
  object-fit: contain;
  object-position: center;
}
.chat-icon {
  width: 50px;
  max-width: 100%;
}

.form-container {
  background-color: #0b0230;
  padding: 32px;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 0 10px 10px 0;
}

.signup-link {
  color: #fff !important;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  background-color: #f49b09eb;
}

.form-container .link {
  text-align: right;
}

.link span {
  display: inline-block;
}
.signin-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.welcome-text {
  color: #ffffff;
  font-size: 17px;
  text-align: left;
  /* padding-bottom: 50px; */
}

.signin-fields {
  padding: 10px 10px 5px 40px !important;
  width: 100% !important;
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 2px;
  color: #fff !important;
}

.signin-fields::placeholder {
  color: grey !important;
  /* font-weight: 400 !important; */
  font-size: 14px;
  font-family: "Montserrat" !important;
}

.signin-button {
  padding: 2%;
  width: 100%;
  background-color: #f49b09eb;
  color: #fff;
  justify-content: center;
  font-size: 16px;
  border: none;
  text-transform: uppercase;
  transition: 1s;
}

.signin-button:hover {
  background-color: #de8c07eb;
}

.signin-button:active {
  background-color: #f3ac3beb !important;
}

.sign-in-email,
.sign-in-pwd {
  position: relative;
}

.input-icon {
  position: absolute;
  bottom: 15%;
  left: 2%;
}

.visible-blind {
  position: absolute;
  bottom: 15%;
  right: 5%;
  color: white;
}

.terms {
  display: flex;
  justify-content: space-between;
}

.anchor {
  color: white;
}

.terms li {
  display: flex;
}

.secured {
  display: none;
}

.blop {
  position: absolute;
  width: 100px;
  top: 30%;
  right: 20%;
}

.blop1 {
  position: absolute;
  width: 100px;
  top: 80%;
  left: 46%;
}

@keyframes fadeinPg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 620px) {
  .signin-container > * {
    color: #0b0230;
  }
  .image-container {
    display: none;
  }
  .welcome-text {
    display: none;
  }

  .signin-logo {
    display: none;
  }
  .mobile-logo {
    display: block;
    margin: 0 auto 30px;
    max-width: 100%;
    width: 213px;
    height: 33px;
  }
  .input-icon {
    color: #0b0230;
  }

  .visible-blind {
    color: #0b0230;
  }

  .form-container {
    padding-top: 5rem;
    width: 100%;
    height: 100%;
    background-color: #fff;
    
  }

  .form-container .link {
    position: relative;
    text-align: center;
    order: 2;
  }

  .signin-fields {
    border: none;
    border-bottom: 1px solid #0b0230;
    border-radius: 0;
    color: #0b0230 !important;
  }

  .signin-button {
    padding: 2%;
    width: 100%;
    margin-bottom: 30px;
    background-color: #f49b09eb;
    color: #fff;
    justify-content: center;
    border: none;
  }

  .signup-link {
    color: #f49b09eb !important;
    background-color: transparent;
  }

  .terms {
    display: none;
  }

  .secured {
    display: flex;
    order: 5;
    margin: 5rem auto 0;
  }
  .secured > span + span {
    padding: 2px 0 0 2px;
  }
}

@media (max-width: 1024px) and (min-width: 621px) {
  .signin-container {
    width: 100%;
  }
  .signin-logo {
    width: 180px;
    height: 30px;
  }

  .bg-text p {
    padding-bottom: 40px;
  }
  .welcome-text {
    text-align: center;
    font-weight: bolder;
    margin-bottom: 2rem;
  }
}
