.helpPage .help-page-container {
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 1rem 0;
}

.help-container {
  background-color: white;
  color: #0e093f;
  width: 500px;
  margin: auto;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  border-radius: 5px;
  padding: 30px;
  position: relative;
}

.help-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
}
.help-header span {
  font-size: 16px;
  font-weight: 500;
}
.help-header-back-icon {
  cursor: pointer;
}

.help-cards-holder {
  width: 95%;
  margin: auto;
}

@media screen and (max-width: 620px) {
  .help-container {
    width: 100%;
  }
}
@media screen and (max-width: 510px) {
  .help-cards-holder {
    width: 100%;
  }
}
