/* TrialNavComp.css */
.dashboard_side-nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 218px;
  background-color: #0b0042;
  color: white;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.dashboard_side-nav::-webkit-scrollbar {
  display: none;
}

.menu_heading {
  display: flex;
  justify-content: center;
  padding: 20px 0 15px 0;
}

.menu_heading img {
  width: 130px;
}

.general_heading {
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-top: 20px;
}

.dashboard_side-nav.open {
  transform: translateX(0);
}

.dashboard_page_link_container {
  background-color: #0b0230;
  display: flex;
  gap: 15px;
  padding: 8px 0 8px 40px;
}

.dashboard_page_link_content {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  color: white;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.dashboard_page_link_content img {
  width: 20px;
}

.dashboard_side-nav .dashboard_pages_navigations {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 10px 10px 40px;
  margin-top: 10px;
}

.dashboard_side-nav .general_pages_navigations {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 10px 10px 40px;
}

.page_nav_link_content {
  width: 100%;
  padding: 8px 5px;
  border-radius: 5px !important;
  background-color: transparent;
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: white;
  text-decoration: none;
}

.card_nav_btn {
  border: none !important;
}

.page_nav_link_content.active {
  background-color: white;
  color: #0f3fb2 !important;
  font-weight: 600 !important;
  border: none !important;
}

.page_nav_link_icon {
  width: 18px;
  height: 18px;
  color: white;
}

.page_nav_link_content.active .page_nav_link_icon {
  color: #0f3fb2;
}

.dashboard_side-nav nav ul li {
  padding: 15px 20px;
}

.dashboard_side-nav nav ul li a {
  color: white;
  text-decoration: none;
  display: block;
}

.dashboard_side-nav .dashboard_mobile-toggle-btn {
  position: absolute;
  top: 10px;
  right: -40px;
  width: 40px;
  height: 30px;
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  background: none;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 0 !important;
}

.dashboard_side-nav.open .dashboard_mobile-toggle-btn {
  transform: translateX(0);
}

.dashboard_mobile-toggle-icon {
  width: 20px;
  height: 20px;
  color: #0b0042 !important;
}

.logout_button_wrapper {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  color: white;
  width: 100% !important;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 15px;
}

.rounded_log_btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e20010;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide menu button on larger screens */
@media (min-width: 769px) {
  .dashboard_side-nav {
    transform: translateX(0);
  }

  .dashboard_side-nav .dashboard_mobile-toggle-btn {
    display: none;
  }
}
