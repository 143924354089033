.outer {
  display: flex;
  max-width: 100%;
  width: 600px !important;
  padding: 0.5rem;
  margin: 10px auto 0;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid #000; */
}

.inner-form {
  /* display: flex; */
  /* flex-direction: column; */
  /* width: 100% !important; */
  padding: 2rem;
  overflow: auto;
  background-color: #fff;
  border-radius: 10px !important;
}

.fields-control {
  height: 100%;
  /* border: 1px solid #000; */
}

.primary-email {
  display: flex;
  flex-direction: row !important;
  margin-top: -20px !important;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 100% !important;
  margin-left: 3rem !important;
  /* border: 1px solid #000; */
}

.make-email-text {
  font-family: AgrandirBold !important;
  font-size: small;
  margin-left: 0rem !important;
  width: auto !important;
  /* border: 1px solid #000 !important; */
}

.profile-text-area {
  font-family: Poppins !important;
  color: #979797 !important;
}

.profile-text-area::placeholder {
  font-family: Poppins !important;
  font-size: 16px;
  color: #979797 !important;
}
.button-control {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 35%;
  margin-top: 2% !important;
  margin-left: 0.5rem !important;
  font-family: Poppins !important;
}

.react-tabs {
  width: 80% !important;
  margin-top: 0.2rem !important;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa !important;
}

.react-tabs__tab {
  font-family: Agrandir !important;
  bottom: -1px !important;
  color: #fff !important;
}

.react-tabs__tab--selected {
  background-color: #0b0230 !important;
  color: #fff !important;
  font-family: AgrandirBold !important;
  border-bottom: 1px !important;
}

@media screen and (max-width: 620px) {
  .outer {
    width: 100% !important;
    margin-left: auto !important;
    margin-left: auto !important;
    margin-top: 2rem !important;
    /* margin-left: auto; */
  }

  .email-label {
    margin-left: 0.5rem !important;
  }

  .email-label-2 {
    margin-left: 0.5rem !important;
  }
}

.first-name {
  margin-left: 0px !important;
}
/* 
.gender {
  width: 62% !important;
  margin-left: 0.5rem;
} */

.name-row {
  display: flex;
  flex-direction: row;
  width: 82% !important;
  margin-left: 1rem !important;
  /* border: 1px solid #000; */
}

/* .email-address {
  width: 237.7px !important;
  margin-left: 7.5% !important;
} */

#email-address-2 {
  width: 237.7px !important;
  margin-left: 20% !important;
}

.phone-number {
  width: 80% !important;
  margin-left: 1.2rem;
}

.other-number {
  width: 80% !important;
  margin-left: 1.2rem !important;
}

.primary-number-text {
  margin-top: 0px !important;
}

.profession {
  width: 80% !important;
}

.address {
  width: 80% !important;
  margin-left: 1.2rem !important;
}

.email-container {
  display: flex !important;
  flex-direction: row !important;
  width: 83% !important;
}

.phone-number-container {
  display: flex !important;
  flex-direction: row !important;
  width: 83% !important;
  /* margin-bottom: -1.5rem !important; */
}
.default-number {
  margin-left: 0px !important;
}

.phone-row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 80% !important;
  margin-left: 1.5rem;
  border: 1px solid #000;
}

.country-state-city {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-left: 1.2rem !important;
  margin-top: -1rem !important;
  width: 80% !important;
}

.select-country {
  width: 65% !important;
  height: 38px !important;
  margin-left: 0% !important;
}

.select-state {
  width: 150px !important;
  margin-left: -5rem !important;
}

.select-city {
  width: 150px !important;
  margin-left: 0.8rem !important;
}

.phone-number-label {
  width: 100%;
  font-family: AgrandirBold !important;
}

@media screen and (max-width: 620px) {
  .labels {
    margin-left: 3% !important;
  }

  .inner-form {
    width: 100% !important;
    /* height: 100%; */
  }

  .text-area {
    width: 95% !important;
  }

  .country-label {
    margin-left: 1rem !important;
  }

  .city-label {
    margin-left: 1rem !important;
  }

  .state-label {
    margin-left: 1rem !important;
  }

  .make-email-text {
    margin-left: -2.5rem !important;
  }

  .react-tabs {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 0.2rem !important; */
  }

  .name-row {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin-left: 0px !important;
  }

  .profession {
    width: 95% !important;
    margin-left: 0.5rem !important;
  }

  .country-state-city {
    display: flex;
    flex-direction: column !important;
    margin-left: -5px !important;
    width: 100% !important;
    /* border: 1px solid #000; */
  }

  .select-country {
    width: 95% !important;
  }

  .email-container {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    /* margin-left: 0.9rem !important; */
  }

  .email-address {
    width: 95% !important;
    margin-left: 0.5rem !important;
  }

  #email-address-2 {
    width: 95% !important;
    margin-left: 0.5rem !important;
  }

  .primary-email-text {
    margin-left: 0.5rem !important;
  }
  .phone-number-container {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  .default-number {
    width: 100% !important ;
  }

  .primary-number-text {
    margin-left: 0.5rem !important;
  }

  .select-country {
    margin-left: 0.8rem !important;
  }

  .select-state {
    width: 95% !important;
    margin-left: 0.8rem !important;
  }

  .select-city {
    width: 95% !important;
    margin-left: 0.8rem !important;
  }

  .address {
    width: 95% !important;
    margin-left: 0.5rem !important;
  }

  .phone-number {
    width: 95% !important;
    margin-left: 0.5rem !important;
    /* margin-bottom: 1.5rem !important; */
  }

  .other-number {
    width: 95% !important;
    margin-left: 0.5rem !important;
  }

  .textarea {
    width: 100%;
    font-family: AgrandirBold !important;
  }
  .button-control {
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .update-button {
    width: 95% !important;
  }
}
