.balance-container {
  width: 100%;
  /* height: 45%; */
  margin-top: 1rem !important;
  padding: 0 !important;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.683);
  box-shadow: 1px 1px 8px rgba(255, 166, 0, 0.742);
  position: relative;
}
.user-balance-container {
  font-family: "PoppinsRegular";
  color: white;
  padding: 1.5rem;
  padding-bottom: 0;
  /* background-color: rgb(102, 81, 50); */
  display: flex;
  align-items: stretch;
  position: relative;
  z-index: 1;
  gap: 1rem;
}
.total-balance {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  border-radius: 10px;
  height: 200px;
  background-image: url("../../assets/images/balance-bcg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.105);
  /* z-index: 1; */
  padding: 3rem 0 0.7rem 0;
}
.total-balance h2 {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.276);
}
.lever-img {
  position: absolute;
  top: 2.5rem;
  left: 2.8rem;
}
.ngn-style {
  font-size: 14px;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.276);
}
.amount-style {
  font-size: 20px;
  font-weight: bold;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.276);
}
.dollar-style {
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.276);
}
.get-dollar-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  padding: 0 20px;
}
.get-dollar-info div {
  background-color: #101c75;
  font-size: 10px;
  padding: 5px;
  border-radius: 6px;
}
.get-dollar-info p {
  font-size: 10px !important;
  font-family: "PoppinsRegular" !important;
}

.vector-blop4 {
  position: absolute;
  width: 220px;
  top: -5%;
  left: 10%;
  z-index: 0;
}
.current-balance {
  width: 40%;
  border-left: 1px solid #e8e8e8;
  padding: 5px 0;
  padding-left: 4px;
  text-align: right;
}
.card-balance-flex {
  align-items: end !important;
}
.current-spending p,
.current-cashback p {
  font-size: 12px !important;
  font-family: "PoppinsRegular" !important;
  padding-bottom: 10px;
}

.ngn-style2 {
  font-size: 12px;
  font-weight: normal;
}
.amount-style2 {
  font-size: 20px;
}
.ngn-style3,
.amount-style3 {
  font-size: 16px;
  font-weight: normal;
}
.user-progress-container {
  font-family: "PoppinsRegular";
  color: white;
  padding: 10px 1.5rem;
  display: flex;
  align-items: stretch;
  gap: 2rem;
}
.progress-content {
  width: 70%;
}
.progress-bar-background {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin: 10px 0;
}

.progress-bar-content {
  height: 100%;
  border-radius: 5px;
}
.progress-details {
  display: flex;
  justify-content: space-between;
}
.progress-details p {
  font-size: 14px !important;
  font-family: "PoppinsRegular" !important;
  padding: 10px 0 0 !important;
}
.toggle-account {
  width: 40%;
  text-align: right;
  /* background-color: brown; */
}
.toggle-account p {
  font-size: 14px !important;
  font-family: "PoppinsRegular" !important;
  padding: 10px 0 0 !important;
}
.toggle-account .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
  border: none;
  box-shadow: none;
  /* background-color: aqua; */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  border: none;
  box-shadow: none;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  border: none;
}

input:checked + .slider {
  background-color: #f49b09eb;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
@media screen and (max-width: 620px) {
  .user-balance-container {
    flex-direction: column;
  }
  .total-balance {
    width: 100%;
  }
  .current-balance {
    border-top: 1px solid #e8e8e8;
    border-left: none;
    width: 100%;
    text-align: center;
  }
  .progress-details p {
    font-size: 12px !important;
  }
}
@media (max-width: 1025px) and (min-width: 769px) {
  .user-balance-container {
    flex-direction: column;
  }
  .total-balance {
    width: 100%;
  }
  .current-balance {
    border-top: 1px solid #e8e8e8;
    border-left: none;
    width: 100%;
    text-align: center;
  }
  .card-balance-flex {
    align-items: center !important;
  }
  .progress-details p {
    font-size: 12px !important;
  }
  .toggle-account {
    text-align: left;
  }
}
