.modal-content {
  width: 30%;
  padding-bottom: 1% !important;
  /* margin-left: 35% !important;
  margin-right: auto !important; */
}

.confirm-payment {
  font-family: AgrandirBold !important;
  font-size: large !important;
}

h5 {
  text-align: center !important;
  font-family: AgrandirBold !important;
  font-size: small !important;
  margin-top: 5% !important;
}

p {
  padding-left: 5% !important;
  font-family: AgrandirBold !important;
  font-size: 14px !important;
}

.p-total {
  color: red !important;
}

.approve-button {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (max-width: 620px) {
  .modal-content {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
