.receipt_close_btn {
  width: unset !important;
  border: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.receipt_close_btn > img {
  width: 20px;
}

#invoice {
  box-shadow: 4px 4px 4px 0px #2e576d;
  background-color: #1c1a2e;
  border-radius: 10px;
  color: white;
  padding: 20px 35px;
  min-width: 388px;
  min-height: 500px;
}

.loading_container {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.receipt-loading-cont {
  width: 100%;
  display: flex;
  justify-content: center;
}

.receipt-loading-cont > img {
  width: 50px;
}

.loading_container > img {
  width: 50px;
}

.className {
  width: 100%;
}
#id {
  font-family: AgrandirBold;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
}
.price_checkout {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.price_checkout span {
  border: 1px solid #d9d9d9;
  padding: 10px 10px;
  font-family: AgrandirRegular;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  width: 100%;
}
.price_checkout :nth-child(1) {
  border-left: none;
}
.price_checkout :nth-child(3) {
  border-right: none;
}
.Invoice_details,
.Buyer_details,
.total_container {
  padding: 20px 15px 15px 15px;
}
.Invoice_details h3 {
  font-family: AgrandirRegular;
  font-size: 16px;
  margin-left: 0 !important;
}

.invoice-detail-point {
  display: flex;
  justify-content: space-between;
  font-family: AgrandirRegular;
}

.naira_detail {
  text-transform: uppercase !important;
  font-size: 18px;
}

.h5 {
  font-family: Agrandir;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
}
.row_details {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  opacity: 0.4;
  margin-top: 3px;
  padding: 0 !important;
}
.row_details_information {
  font-family: Agrandir;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-top: 3px;
}

.Buyer_details h3 {
  font-family: AgrandirRegular;
  font-size: 16px;
  margin-left: 0 !important;
  text-transform: uppercase;
}
.Buyer_details h5 {
  color: #f40909;
  text-align: left !important;
}
.Buyer_details p {
  opacity: 0.4;
  padding: 0 !important;
}
hr {
  margin: auto;
}

.total_container p {
  padding: 0 !important;
}

.Payment_received {
  text-align: start;
  margin: 1rem 2rem;
}
.Payment_received p {
  margin-bottom: 0rem;
  font-family: AgrandirRegular;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
}
.Payment_received span {
  color: #f49b09;
}
.Payment_received main {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5rem;
}
.Payment_received main h6 {
  color: #f49b09;
}
/* .qrcodeCon{
  background-color: transparent;
} */
.accept_decline {
  display: flex;
  gap: 2rem;
  padding: 20px 0;
}
.accept_decline button {
  width: 100%;
  border: none;
  font-family: AgrandirRegular;
}
.accept_decline button:nth-child(1) {
  background-color: #f49b09;
  color: black !important;
}
.accept_decline button:nth-child(2) {
  background-color: green;
  color: white !important;
}
.pd-invoice-nt {
  margin-top: 8rem;
  text-align: center;
  font-family: "Agrandir-bold";
  color: white;
}

.paid_Invoice-cancel_btn{
    border: none;
    background-color: #0e093f;
    border: 1px solid gray;
    padding: 10px;
    color: white;
    margin-top: 10px;
    border-radius: 10px;
    width: 100% !important;

}

@media print {
  #invoice {
    background-color: #1c1a2e;
    width: 80%;
    height: 100%;
    margin-top: 2rem;
  }
}
@media (max-width: 620px) {
  #invoice {
    margin-top: 2rem;
  }
}
