/* General card container */
.cardsContainer {
  background-color: #0b0230;
}
/* Card categories styling */
#cardcategories {
  width: 90%;
  text-align: center;
  border-radius: 10px;
  background-color: #201e34;
  box-shadow: 4px 4px 2px 2px #00000066;
  margin-top: 8rem;
  margin-bottom: 5rem;
}
@media screen and (max-width: 620px) {
  #cardcategories {
    width: 100%;
    margin-top: 2rem;
    margin-left: .5rem;
    margin-bottom: 4rem !important;
    padding: 0 !important;
    background-color: transparent;
  }
  #cardcategories .card_con{
    margin-bottom: 2rem;
  }
  #cardcategories .card_btm{
    margin-bottom: 2rem;
  }
}
.Kyc-upgrade-notice {
  font-family: AgrandirBold;
  font-size: 14px;
  color: white;
  padding: 24px 32px;
  border-radius: 10px;
  text-align: justify;
  margin-left: auto;
  background-color: #0c0c0c;
  cursor: pointer;
}
#Kyc-upgrade-notice-1 {
  /* margin-left: auto; */
}
.Kyc-upgrade-notice .gold {
  font-weight: 700;
  color: #f49b09;
}
.Kyc-upgrade-notice strong {
  font-weight: 800;
  color: #f49b09;
  font-size: 14px;
}
.Kyc-upgrade-notice .limit {
  font-style: italic;
  font-weight: 900;
  color: #cd4729;
}
@media screen and (max-width: 620px) {
  #cardcategories {
    width: 100%;
  }
}
@media (max-width:1024px) and (min-width: 621px){
  .Kyc-upgrade-notice{
    font-size: 12px;
    line-height: 20px;
  }
}