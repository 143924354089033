.verification-loader-container {
  background: #fff;
  box-shadow: 0 0 1px 1px grey;
  height: fit-content;
  border-radius: 30px;
  text-align: center;
  padding: 10px 50px;
  margin-left: 60px;
  margin-top: -70px;
  transition: 0.5s ease;
  display: flex !important;
  font-family: AgrandirBold;
  /* padding-left: 10px !important; */
}
.kyc-wait-message {
  color: #5f89f6;
}
.verification-loader-container h5 {
  margin-right: 30px;
  margin-top: 20px;
}
.kyc-return-message-container {
  position: absolute;
  left: 25%;
  top: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  border-radius: 40px;
  background: #fff;
  width: 50%;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 0 35px #000;
  display: flex;
  /* margin-top: -50%; */
  /* margin-left: 18%; */
  /* box-shadow: -4px -15px 0px 19px rgba(237, 233, 233, 0.42);
  -webkit-box-shadow: -4px -15px 0px 19px rgba(237, 233, 233, 0.42);
  -moz-box-shadow: -4px -15px 0px 19px rgba(237, 233, 233, 0.42); */
}
.kyc-return-message-container p {
  /* color: #84632e; */
  font-family: AgrandirBold;
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
  font-weight: bold;
}
.kyc-return-message-container span {
  color: black;
  font-family: AgrandirBold;
}
/* .p-container {
  margin-top: 80px;
}
.kyc-return-message-container img {
  margin-top: 60px;
} */


.kyc-success-message-container {
  height: fit-content;
  align-items: center;
  padding: 40px 0 !important;
  overflow: hidden;
  box-shadow: 0 0 35px #000;
  border-radius: 40px;
  position: absolute;
  left: 30%;
  top: 70%;
  width: 40%;
  background: #fff;
}
.kyc-success-message-container img {
  /* border: solid 1px black; */
  height: 170px;
  width: 170px;
  margin-left: 40px;
}
.kyc-success-message-container h3 {
  color: #5b3d18;
  font-family: AgrandirBold;
}
.success-checkmark {
  margin-top: 68px;
  margin-left: -140px;
}

.slide-left {
  animation: 2s slide-left;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}
.slide-right {
  animation: 2s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}