.merchant-modal-container {
  background-color: #000000dd;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.merchant-modal {
  background-color: white;
  width: 500px;
  height: 500px;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 10px 15px -3px rgba(255, 254, 254, 0.333),
    0 4px 6px -4px rgba(255, 254, 254, 0.326);
  position: relative;
  transition: all 300ms ease;
}
.scale-in {
  transform: scale(1);
  opacity: 1;
}
.scale-out {
  transform: scale(0.75);
  opacity: 0;
}
.merchant-modal-close-icon {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
}
.merchant-modal-container h2 {
  color: #0e093f;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  text-align: center;
  margin: 0 !important;
  padding-bottom: 24px;
}
.merchant-modal-section {
  border: 1.5px solid #ebebeb;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.merchant-modal-main {
  display: flex;
  align-items: center;
  gap: 20px;
}
.merchant-modal-icon {
  background-color: #f49b09eb;
  flex-shrink: 0;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.merchant-modal-cardInfo h3 {
  font-size: 16px;
  font-weight: 600;
  color: #0f3fb2;
  margin: 0 !important;
}
.merchant-modal-cardInfo span {
  font-size: 14px;
  color: #6c6c6c;
}
.merchant-modal-button {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 20px;
}
.merchant-modal-button button {
  width: 150px;
  background-color: #f49a0986;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
  color: #0f3fb2;
  font-size: 12px;
}
.merchant-modal-button span {
  font-size: 10px;
  color: #55bbb2;
}

@media screen and (max-width: 620px) {
  .merchant-modal {
    width: 90%;
  }
}
@media screen and (max-width: 510px) {
}
@media screen and (max-width: 374px) {
}
