.success-modal-container {
  background-color: #000000dd;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-modal {
  background-color: white;
  width: 320px;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 10px 15px -3px rgba(255, 254, 254, 0.333),
    0 4px 6px -4px rgba(255, 254, 254, 0.326);
  position: relative;
  transition: all 300ms ease;
}
.scale-in {
  transform: scale(1);
  opacity: 1;
}
.scale-out {
  transform: scale(0.75);
  opacity: 0;
}

.success-modal-close-icon {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
}
.success-icon-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.success-modal-text {
  text-align: center;
  color: #18b30a;
  font-weight: 500;
  padding-top: 16px;
  line-height: 28px;
}
.success-modal-text p {
  font-size: 20px !important;
  font-family: Poppins !important;
  padding: 0 !important;
}

/* @media screen and (max-width: 620px) {
  .chatus-modal {
    width: 90%;
  }
  .chatus-input-container {
    flex-direction: column;
  }
  .chatus-input-container .name,
  .chatus-input-container .email_con {
    width: 100%;
  }
} */
