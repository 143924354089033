.internet_page{
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.internet_card_container{
  width: 500px;
  height: 600px;
  border-radius: 10px;
  background-color: #0F3FB2;
}

.internet_full_screen{
  height: 100%;
}

@media screen and (max-width: 500px) {
  .internet_card_container{
    width: 100%;
  }
}
