.mainDiv {

  margin-top: 40px;
  margin-left: 300px;

  width: 100%;
  max-width: 500px;
  background-color: white;
  padding: 1rem;

  border-radius: 10px;
  color: #333;
  position: relative;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: -1px;
}
.backup{
  margin-top: 100px;
}
.input::placeholder {
  font-size: 12px;
  color:rgb(130, 130, 130);
}
.header {
padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 100%;
  position: absolute;

}

.header h2{
  font-size: 25px;
  font-family: Poppins;
  color:black;
  margin: 0; 
}
.infoTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.infoTable td {
  padding: 10px;

}

.infoTable td:first-child {
  font-weight: 500;
  color: #0f3fb2;
}

.infoTable td:last-child {
  color: #979797;
}

.infoTable tr:last-child td {
  border-bottom: none;
}
.tableLine {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  height: 2px; 
  background-color: #979797;
  margin-top: 5px; 
}

.formlabel1{
 display: flex;
 align-items: center;
 justify-content: center;
  color: #0F3FB2;

}

.cancelIcon {
  position: absolute;
  left: 20px; 
  top: 78%;
  transform: translateY(-50%);
}

.modalTitle {
  font-size: 25px;
  font-family: Poppins;
  color: #fff;
  margin: 0; 
}

.formGroup {
  margin-bottom: 15px;
}

.formLabel {
  display: flex;
  font-size: 18px;
  font-family: Poppins;
  margin-bottom: 10px;
  color: #0f3fb2;
  margin-left: 24px;
}

.activeText {
  color: #0f3fb2;
}

.formGroup input {
  width: 90%;
  padding: 8px;
  margin-bottom: 17px;
  margin-left: 21px;
  box-sizing: border-box;
  border-radius: 10px;
}

.input {
  border: 1px solid #ccc;
}

.inputActive {
  border-color: #0f3fb2;
}



.dropdown {
  position: relative;
}

.select {
  width: 90%;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-left: 21px;
}

.dropdownIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.errorMessage {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.buttonSubmit,
.buttonCancel {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: -25px;
}

.buttonSubmit {
  background-color: #0f3fb2;
  color: white;
}

.buttonCancel {
  background-color: #dedada;
  color: #000;
}
.amountDiv {
  display: flex;
  justify-content: flex-end; /* Align content to the right */
  margin-top: 0px; /* Adjust the margin if needed */
}

.amountText {
  font-size: 20px; /* Adjust the font size if needed */
  color:#0f3fb2; /* Set the color as needed */
}
.successMessage {
  color: #58b20f; /* Set the color to your desired hex code */
}
.eyeIcon2 {
  margin-left: 400px;
  margin-top: -55px;
}
.dataPlansRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .mainDiv {
    margin-left: auto;
    margin-right: auto;
  }

  .eyeIcon2 {
    margin-left: 80%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .mainDiv {
    margin-left: auto;
    margin-right: auto;
  }

  /* .eyeIcon2 {
    margin-left: 290px;
    margin-top: -55px;
  } */
}