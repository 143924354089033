.successfull_sub_modal {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
}

.successful_card_wrapper {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 500px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.mod_kyc_sub_text1 {
  font-family: "Poppins", sans-serif !important;
  padding: 0 !important;
  font-size: 12px !important;
  color: #0f3fb2 !important;
  font-weight: 500 !important;
}

.mod_kyc_sub_text2 {
  font-family: "Poppins", sans-serif !important;
  padding: 0 !important;
  font-size: 12px !important;
  color: #979797 !important;
  font-weight: 400 !important;
}

.mod_kyc_sub_text2 span {
  font-weight: 700 !important;
}

.kyc_to_dashboard_link{
  border-radius: 10px;
  background-color: #0f3fb2;
  padding: 10px 40px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  .successful_card_wrapper {
    max-width: 3500px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    padding: 30px 10px;
  }
}
