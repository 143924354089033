.overlay {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(169, 185, 180, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; 
    margin-bottom: -10px;
  }
  
  .wrapper2 {
    padding-top: 350px;
    padding-left: 300px;
    padding-right: 20px;
    width: 80%; 
    max-width: 900px;
}

  
  .main {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 50px;
  
    padding-left: 10px;
    padding-right: 110px;
    padding-bottom: 10px;
    position: relative;
  }
  
  .innerDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    /* padding-left: 50px; */
  }
  
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .text p {
    color: rgb(15, 63, 178);
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 5%;
    text-align: right;
  }
  

  .DButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-right: 50px;
    margin-left: 50px;
  /* margin-left: 100px; */
  }
  
  .downloadButton {
    background-color: rgb(15, 63, 178);
    color: white;
    border: none;
    padding: 10px 20px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .downloadButton:hover {
    background-color: rgb(10, 50, 120);
  }
  
  .downloadOptions {
    display: flex;
    flex-direction: row; /* Change to row for horizontal alignment */
    gap: 10px;
    margin-right: 90px;
    margin-left: 20px;
    align-items: center;
  }
  
  .downloadOptions button {
    background-color: rgb(15, 63, 178);
    color: white;
    border: none;
    /* padding: 10px 20px; */
    cursor: pointer;
    font-size: 13px;
    font-family: Poppins;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .downloadOptions button:hover {
    background-color: rgb(10, 50, 120);
  }
  .cancelIcon {
    position: absolute;
    top: -5px;
    right: 20px;
    font-size: 50px;
    color: red;
    cursor: pointer;
  }
  
  .cancelIcon:hover {
    color: rgb(10, 50, 120);
  }
  
 /* Small devices (600px and down) */
@media only screen and (max-width: 600px) {
  .wrapper2 {
    padding-top: 440px;
    padding-left: 10px;
    padding-right: 10px; /* Added for better alignment */
    width: 100%; /* Ensure full width on small screens */
  }

  .main {
    padding: 1rem;
    border-radius: 10px;
    gap: 30px;
    /* padding-right: 10px;  */
    margin-left: 12px;
    width: 93%; /* Ensure full width on small screens */
  }

  .innerDiv {
    padding-left: 0;
    gap: 30px;
  }

  .text p {
    font-size: 18px;
    text-align: center;
  }

  .done {
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    gap: 30px;
  }

  .doneButton {
    font-size: 18px;
  }

  .downloadOptions {
    flex-direction: column; /* Stack buttons vertically */
    margin-left: 0; /* Remove margin on small screens */
    align-items: center; /* Center buttons horizontally */
    justify-content: center; /* Center buttons vertically */
    text-align: center;
  }

  .downloadOptions button {
    font-size: 14px; /* Adjusted for small screens */
    padding: 8px 16px; /* Reduced padding for small screens */
    margin: 5px 0; /* Add margin for spacing between buttons */
   
    margin-left: 100px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .wrapper2 {
    padding-bottom: 400px;
    /* padding-left: 20px;
    padding-right: 20px; */
  }

  .main {
    padding: 1rem;
    border-radius: 10px;
    gap: 40px;
    width: 100%;
    margin-right: 30%;
    /* padding-right: 20px; */
  
  }

  .innerDiv {
    padding-left: 20px;
    gap: 40px;
  }

  .text p {
    font-size: 20px;
    text-align: center;
  }

  .done {
    padding-left: 0;
    padding-right: 20px;
    align-items: center;
    gap: 40px;
  }

  .doneButton {
    font-size: 20px;
  }

  .downloadOptions {
    flex-direction: row; /* Row direction for medium screens */
    margin-left: 0;
    align-items: center; /* Center buttons vertically */
    justify-content: center; /* Center buttons horizontally */
  }

  .downloadOptions button {
    font-size: 15px;
    padding: 10px 20px; /* Adjusted padding for medium screens */
    margin: 0 5px; /* Add margin for spacing between buttons */
  }
}

/* Large devices (desktops, 1025px and up) */
@media only screen and (min-width: 1025px) {
  .wrapper2 {
    padding-top: 330px;
    /* padding-left: 30px;
    padding-right: 30px; */
  }

  .main {
    padding: 1rem;
    border-radius: 10px;
    gap: 30px;
    /* padding-right: 10px;  */
    margin-left: 10px;
    width: 86%;
  }

  .innerDiv {
    padding-left: 30px;
    gap: 50px;
  }

  .text p {
    font-size: 22px;
    text-align: right;
  }

  .done {
    padding-left: 0;
    padding-right: 30px;
    align-items: flex-end;
    gap: 50px;
  }

  .doneButton {
    font-size: 22px;
  }

  .downloadOptions {
    flex-direction: row; /* Ensure row direction for large screens */
    margin-left: 100px; /* Maintain margin on large screens */
    align-items: center; /* Center buttons vertically */
    justify-content: center; /* Center buttons horizontally */
  }

  .downloadOptions button {
    font-size: 13px;
    padding: 10px 20px; /* Standard padding for large screens */
    margin: 0 10px; /* Add margin for spacing between buttons */
  }
}
