.Toastify__toast {
    background-color: #333 !important;
    color: #fff !important; 
}

.Toastify__toast--success {
    background-color: #28a745 !important;
}

.Toastify__toast--error {
    background-color: #dc3545 !important;
}
