.disabled {
  cursor: not-allowed;
  opacity: 0.6;
  position: relative;
}
.funds-side-container {
  color: white;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.176);
  padding: 1rem;
  position: relative;
  z-index: 10;
  margin-bottom: 1rem;
}
.funds-side-icon-container {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.side-icon-container {
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fund-side-text-container p {
  font-weight: 500;
  font-size: 10px !important;
  font-family: "PoppinsRegular" !important;
  text-align: center;
}
.biller-component-container {
  color: white;
  border: 1px solid white;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.176);
  padding: 1rem;
  position: relative;
  z-index: 10;
  margin-bottom: 1rem;
}
.biller-icon-container {
  width: 240px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.biller-icon-container a,
.funds-side-icon-container a {
  color: white;
  text-decoration: none;
}
.fund-side-text-container,
.icon-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
}
.icon-container {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-text-container p {
  font-weight: 500;
  font-size: 14px !important;
  font-family: "PoppinsRegular" !important;
}
.yellow {
  background-color: #ffb801;
}
.white {
  background-color: #dbd8e8;
}
.red {
  background-color: #c22f24;
}
.blue {
  background-color: #2e67ee;
}
.green {
  background-color: #c5ece0;
}
.coming-soon-label {
  font-size: 10px;
  color: #ff0000;
  background-color: white;
  margin-top: 2px;
  position: absolute;
}

@media (max-width: 1100px) and (min-width: 769px) {
  .funds-side-icon-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1075px) and (min-width: 769px) {
  .biller-icon-container {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  .icon-container {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
}
@media (max-width: 845px) and (min-width: 769px) {
  .funds-side-icon-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .icon-container {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
}
