.card-balance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}
@media screen and (max-width: 620px) {
  .card-balance-flex {
    flex-direction: row !important;
  }
  .card-balance main strong {
    font-size: 12px !important;
  }
  .card-balance main p {
    opacity: 0.8;
    font-size: 10px !important;
  }
}
@media (max-width: 1024px) and (min-width: 621px) {
  .card-balance {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }
  .card-balance h4 {
    font-size: 15px;
  }
}
.card-balance img {
  background-color: #353255;
  padding: 5px;
  outline: 7px solid #353255;
  border: none;
  border-radius: 50%;
  margin: 0;
}
.card-balance main {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
  margin: 0;
  padding-left: 6px;
  padding-right: 6px;
}
.card-balance p {
  font-size: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.card-balance small {
  color: white;
  text-align: left !important;
}
.card-balance main {
  width: 70%;
}
.card-balance main strong {
  font-size: 18px !important;
  margin: 0 !important;
  padding: 0;
}
