.kyc-text-input input {
  padding: 10px 20px;
  border: 1px solid #000000;
  border-radius: 10px;
  /* margin-top: 5px; */
  margin-bottom: 20px;

  border-radius: 10px !important;
  font-family: AgrandirBold;
  font-weight: 900;
  color: black !important;
}
::placeholder {
  color: black !important;
  font-family: AgrandirBold;
  font-size: 20px;
}
