.kyc_verification_main_container {
  width: 100% !important;
}

.kyc_verification_main_container > h1 {
  color: white !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px !important;
}

.kyc_fieldstep_container {
  background-color: white;
  margin: 0 auto 20px auto;
  border-radius: 5px;
  min-height: 450px;
  width: 700px;
  padding: 15px 20px;
}

.kyc_fieldstep_container > h2 {
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
  color: #979797 !important;
  text-align: center;
  font-weight: 500;
  margin: 0 !important;
}

.step_outer_wrapper {
  width: 320px;
  margin: auto;
}

.kyc_form_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.general_form_field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input_button_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.general_form_field label {
  color: #0f3fb2 !important;
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  border: none !important;
  padding: 0 !important;
  cursor: unset !important;
  display: inline !important;
}

.input_button_container input,
.input_button_container textarea {
  width: 300px !important;
  border: 1px solid #828282 !important;
  border-radius: 5px;
  padding: 10px 15px !important;
  font-family: "Poppins", sans-serif !important;
  color: black !important;
  font-size: 12px !important;
  box-shadow: 1px 2px 5px #828282;
}

.input_button_container textarea {
  resize: none;
}

.input_button_container input::placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #979797 !important;
  font-size: 12px !important;
}

.input_button_container button {
  width: 90px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  border-radius: 5px;
  font-family: "Poppins", sans-serif !important;
  color: white !important;
  font-size: 12px !important;
  background-color: #329521 !important;
}

.input_button_container button.button-pending {
  background-color: #f49b09eb !important;
}

.input_button_container input.input-disabled,
.input_button_container textarea.input-disabled {
  background-color: #329521;
  box-shadow: none;
  color: white !important;
}

.label_span {
  font-size: 10px !important;
  color: #cd4729;
}

.kyc_submit_btn {
  width: 300px !important;
  background-color: #0f3fb2;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.kyc_submit_btn:disabled {
  background-color: #828282;
}

@media screen and (max-width: 769px) {
  .kyc_fieldstep_container {
    margin: 0 auto 20px auto;
    min-height: 450px;
    width: 100%;
  }

  .step_outer_wrapper {
    width: 100%;
  }

  .input_button_container input#image {
    width: 100% !important;
  }

  .kyc_submit_btn {
    width: 100% !important;
  }
}

@media screen and (max-width: 360px) {
  .kyc_form_wrapper {
    gap: 15px;
  }

  .input_button_container {
    flex-direction: column;
    align-items: unset;
    gap: 10px;
  }

  .input_button_container input,
  .input_button_container textarea {
    width: 100% !important;
  }
}
