.modal-top {
  height: 100px;
  width: 100%;
}

.modal-top img {
  margin-left: 10px;
}
.logo-pen {
  flex-grow: 1;
}
.modal-top span {
  color: #186317;
  font-size: 20px;
}

.modal-top small {
  color: #000000;
  font-size: 13px;
}
.modal-top h6 {
  color: #172c63;
}
.sliderBody {
  background: #fff;
  height: 200px !important;
  display: block !important;
}

.otp-div img {
  height: 30%;
}
.otp-div,
.modal-main p {
  color: #172c63;
  line-height: 19px;
  font-size: 16px;
  font-family: AgrandirBold;
}
.slider-container {
  margin-top: 40px !important;
  padding: 0px 30px;
  left: 50% !important;
}
.sliderContainer {
  background: #0b0230 !important;
}
.closeBtn {
  display: none;
}
.otp-btn input {
  background: #e7e98f !important;
  border: solid 2px #a4a642 !important;
  color: #000000 !important;
  font-family: AgrandirBold !important;
  margin-top: 5px;
}
.btn-cancel {
  background: #d9d9d9;
  color: #000000;
  width: 60%;
  font-family: AgrandirBold;
  font-size: 15px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  /* padding: 5px 10px !important; */
}
.btn-cancel img {
  margin-right: 5px;
}
.authorize-btn {
  background: #5fce2f !important;
  border: solid 2px #0c6904 !important;
  color: #fff !important;
  font-family: AgrandirBold;
  padding: 15px 10px !important;
  border-radius: 10px !important;
  width: 100%;
}
.authorize-btn:disabled {
  background: #bee1af !important;
}

.blue-link {
  color: #082e88 !important;
  text-decoration: none;
}
@media screen and (max-width: 620px) {
  .slider-container {
    left: 5% !important;
  }
}
.otp-slide {
  left: 40%;
}
@media screen and (max-width: 620px) {
  .otp-slide {
    left: 5%;
  }
}
