.mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px #0000001a;
  backdrop-filter: blur(10px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  border: 1px solid rgba(255, 255, 255, 0.3); 
}

.mainDiv h2 {
  font-size: 30px;
  font-weight: bold;
  font-family: Poppins;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #333;
}

.btnDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.btnDiv p {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.buttons-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 370px;
  height: 100px;
  padding: 1rem;
  color: #0F3FB2;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  box-sizing: border-box;
  background-color: #DBD8E8;
  margin: 0 0.5rem;
  
}

.button .icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.button .text {
  font-size: 0.9rem;
}

.button:hover {
  transform: scale(1.05);
}

.error {
  color: red;
  font-weight: bold;
}

@media only screen and (max-width: 620px) {
  .mainDiv {
    width: 98%;
    padding: 1rem;
  }
  
  .btnDiv {
    width: 90%;
    max-width: 320px;
  }
  
  .button {
    width: 100px;
    height: 100px;
  }
  
  .button .icon {
    font-size: 1.5rem;
  }
  
  .button .text {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 621px) {
  .mainDiv {
    width: 65%;
    margin-top: 2rem;
  }
}