@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.modal-content {
  display: block !important;
  background-color: #fff !important;
  font-family: "Montserrat", sans-serif !important;
  width: 100% !important;
  height: 100% !important;
  margin: auto !important;
  border: 1px solid black !important;
  /* margin-top: auto !important; */
  /* margin-left: auto !important;
  margin-right: auto !important; */
  /* margin-bottom: auto !important; */
  padding: 0rem !important;
}

.modal-header {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header p {
  padding: 0 !important;
  font-family: "Montserrat", sans-serif !important;
  color: black;
  font-size: 13px !important;
  font-weight: 800;
  text-align: center !important;
}

span.modal_pay {
  font-size: 16px;
  font-weight: 800;
  color: #172c63;
  font-family: "Montserrat", sans-serif !important;
}

span.modal_header_amount {
  font-size: 20px;
  font-weight: 800;
  color: #5fce2f;
  font-family: "Montserrat", sans-serif !important;
}

/* .tOtp {
  background-color: white !important;
  width: 30%;
} */
.otp-logo {
  width: 10% !important;
}

.phone-otp {
  display: block;
  width: 25% !important;
  margin-top: 0.9rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  transform: rotate(180deg);
  background-color: white;
}

.otp-message {
  padding: 0 !important;
  width: 260px;
  text-align: center;
  margin: 20px auto;
  color: #172c63;
  font-size: 14px !important;
  font-weight: 800 !important;
  font-family: "Montserrat", sans-serif !important;
}

.otp-resend-msg {
  padding: 0 !important;
  width: 239px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: black;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}

.color-link {
  color: #172c63;
}

.pointer {
  cursor: pointer;
}

.font-style {
  font-family: AgrandirBold !important;
}

.otp-field {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  width: 85%;
}

.otp-input {
  font-family: AgrandirBold !important;
  font-size: 0.9em !important;
  width: 55% !important;
  padding: 2% !important;
  background-color: #e7e98f !important;
}

.otp-input::placeholder {
  font-family: AgrandirBold !important;
  font-size: 0.9em !important;
}

.auth-btn {
 font-family: AgrandirBold !important;
  font-size: 0.8em !important;
  background-color: #5fce2f !important;
  color: #fff;
  width: 40%;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-btn.loading {
  background-color: #aaa;  /* Change color when loading */
}

.auth-btn:disabled {
  background-color: #ccc;  /* Disable color */
}


.cancel-payment {
  display: block;
  width: 50% !important;
  margin: 20px auto !important;
  text-align: center !important;
}

.cancel-sign {
  width: 8%;
  margin: auto;
}

.modal-footer {
  justify-content: center;
  align-items: center !important;
}

.footer-style {
  font-size: 0.9em !important;
  color: black;
  font-weight: 500;
}

@media screen and (max-width: 620px) {
  .modal-content {
    /* display: block !important; */
    width: 100% !important;
    height: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .cancel-payment {
    display: block;
    width: 55% !important;
    /* padding: 2%; */
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 2%;
    text-align: center !important;
  }

  .cancel-sign {
    width: 5%;
    margin: auto;
  }
}
