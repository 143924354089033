.callus-modal-container {
  background-color: #000000dd;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.callus-modal {
  background-color: white;
  width: 500px;
  height: 300px;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 10px 15px -3px rgba(255, 254, 254, 0.333),
    0 4px 6px -4px rgba(255, 254, 254, 0.326);
  position: relative;
  transition: all 300ms ease;
}
.callus-modal-icon {
  background-color: #18b30a;
  flex-shrink: 0;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.callus-modal-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.callus-modal-container h2 {
  color: #0e093f;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  text-align: center;
  margin: 0 !important;
  padding-bottom: 24px;
}
.callus-modal-button p {
  width: 150px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
  color: #0f3fb2;
  font-size: 12px;
  font-family: Poppins !important;
  padding: 0 !important;
}
.callus-modal-button button {
  width: auto;
  background-color: #3ad4a7;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}
@media screen and (max-width: 620px) {
  .callus-modal {
    width: 90%;
  }
}
