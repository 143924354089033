.header_logo_profile_wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
}

.track_exchange_container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.track_exchange_container .get_track {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500;
  color: #979797;
  padding: 0 !important;
  width: auto !important;
}

.track_exchange_container .exchange_rate {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500;
  color: white;
  padding: 0 !important;
  width: auto !important;
}

.track_exchange_container .exchange_rate span {
  font-weight: 600;
  color: #329521;
}

.user_profile_component {
  border-radius: 50px;
  border: 2px solid #28272f;
  padding: 10px 14px;
  background-color: #1f1e29;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.user_profile_component .straight_line {
  width: 2px;
  height: 19px;
  background-color: #3e3e47;
}

.user_profile_component .profile_img_container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.user_profile_component .profile_img_container img {
  width: 100% !important;
}

.user_profile_component p {
  text-transform: uppercase;
  font-family: "Outfit", sans-serif !important;
  font-size: 12px !important;
  padding: 0 !important;
  font-weight: 500 !important;
  color: #f6f6f6 !important;
}

.user_profile_component .link_arrow_dw {
  text-decoration: none !important;
}

.user_profile_component .link_arrow_dw .arrow_dw {
  color: white !important;
}

.user_profile_component .link_arrow_dw .arrow_dw:hover {
  color: #28272f !important;
}

@media screen and (max-width: 769px) {
  .header_logo_profile_wrapper {
    justify-content: end;
  }

  .header_logo_profile_wrapper > img {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .user_profile_component {
    border-radius: 50px;
    padding: 6px 10px;
    gap: 5px;
  }

  .user_profile_component p {
    display: none;
  }

  .track_exchange_container {
    flex-direction: column;
    display: flex;
    gap: 0;
    align-items: unset;
  }
}
