.bundle_main_card_container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bundle_options_card_container{
  background-color: white;
  border-radius: 10px;
  height: 100%;
  padding: 15px 15px 0 15px;
}

.bundle_options_card_container > h2{
  font-family: "Poppins", sans-serif !important;
  color: #0F3FB2 !important;
  text-align: center;
  margin: 10px 0 !important;
}

.short_details_card{
  background-color: #0F3FB2;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 15px;
}

.short_details_card h3{
  font-family: "Poppins", sans-serif !important;
  margin-left: 0 !important;
  font-size: 14px !important;
  color: white !important;
  font-weight: 600 !important;
}

.short_details_card p{
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  color: white !important;
  font-weight: 400 !important;
  padding: 0 !important;
}

.bundle_summary_card{
  padding: 20px;
}

.bundle_summary_card > h3{
  text-align: center;
  margin: 0 0 15px 0 !important;
  color: #0F3FB2;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.bundle_summary_card > .group_summary{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 2px solid #C3C7E5;
}

.individual_summary{
  display: flex;
  justify-content: space-between;
}

.individual_summary .summary_heading{
  font-size: 14px !important;
  color: #0F3FB2 !important;
  font-family: "Poppins", sans-serif !important;
  padding: 0 !important;
  font-weight: 500 !important;
}

.individual_summary .summary_value{
  width: 150px !important;
  font-size: 12px !important;
  color: #979797 !important;
  font-family: "Poppins", sans-serif !important;
  padding: 0 !important;
}

.summary_input_group{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.summary_input_group label{
  color: #0F3FB2 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  padding: 0 !important;
  border: 0 !important;
  font-weight: 500 !important;
}

.summary_input_group input{
  border: 1px solid #C3C7E5 !important;
  border-radius: 10px !important;
  padding: 10px 15px !important;
  color: #C3C7E5 !important;
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
}

.summary_input_group input::placeholder{
  color: #C3C7E5 !important;
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
}

.summary_input_group button{
  width: 100% !important;
  background-color: #0F3FB2 !important;
  margin-top: 20px;
}
