.mCOnn {
  width: 100% !important;
}
.merchant_COn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.merchant-container {
  height: fit-content;
  background: #10133f;
  border-radius: 20px;
  padding: 1rem 1rem 24px !important;
  width: 100% !important;
}
.merchant-container .shop-more {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 !important;
}
.merchant-container .shop-more h4 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 16px;
}

.merchant-header {
  display: flex;
  /* padding-left: 1rem; */
  width: 100%;
}
.merchant-header h3 {
  font-family: AgrandirBold;
  font-size: large !important;
  color: #fff;
}
.merchant_search {
  margin-top: -10px;
}

.merchants {
  margin-top: 20px;
  position: relative;
  z-index: 100;
}
.merchant-text {
  font-size: 12px !important;
}
.merchants h4 {
  color: #0f3fb2 !important;
  font-family: "PoppinsRegular" !important;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 5px;
  margin: 0;
}
.merchants button {
  background-color: #f9b507bd;
  font-family: "PoppinsRegular" !important;
  font-weight: 600;
  font-size: 12px;
  width: 130px;
  padding: 5px;
  border: none;
  border-radius: 4px;
  margin-left: 2rem;
}
.details-link {
  color: #fff !important;
  font-family: AgrandirBold;
  text-decoration: none;
}
.second-container {
  background: linear-gradient(to right, #122b49 0%, #122b4927 100%) !important;
  /* background: #122b49 !important; */
}
.second-container h4 {
  color: white !important;
  width: 150px;
  margin-left: 8px;
}
.merchants .button-style {
  background-color: transparent;
  color: #887c7c;
  margin-left: 0;
}

@media screen and (max-width: 620px) {
  .merchant-header h3 {
    text-align: left;
  }
  .merchant_COn {
    /* margin-left: -1rem; */
  }
  .merchant-header span,
  .merchant-header a {
    display: none;
  }
  .merchant-container {
    margin-left: 0px;
  }
  .merchants .button-style-one {
    padding: 5px 3px;
    /* margin-left: 0; */
    width: 120px;
  }
}
@media (max-width: 1024px) and (min-width: 621px) {
  .merchant-header h3 {
    font-size: 20px;
  }
  .details-link {
    display: none;
  }
  .merchant_search {
    margin-top: -1rem;
  }
  /* .merchant-container {
    margin-left: 20px;
  } */
}
