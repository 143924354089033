.bundle_card_container{
  min-height: 100px;
  background-color: #0F3FB2;
  border-radius: 5px;
  position: relative;
}

.bundle_card_wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  position: relative;
}

.bundle_card_wrapper>div{
  padding-left: 20px;
  padding-right: 20px;
  color: white !important;
}

.bundle_card_wrapper p{
  font-family: "Poppins", sans-serif !important;
  font-family: 14px !important;
  padding: 0 !important;
}

.p_b{
  font-size: 20px !important;
  font-weight: 600 !important;
}

.bundle_card_lower{
  display: flex;
  justify-content: end;
}

.validity_container{
  border-right: 2px solid white;
  padding-right: 20px;
  width: 130px;
}

.validity_container > div{
  display: flex;
  align-items: center;
  gap: 3px;
}

.price_buy_container{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.price_buy_container>div>div{
  display: flex;
  align-items: center;
  gap: 3px;
}

.price_buy_container>button{
  border: 2px solid #F49B09EB !important;
  border-radius: 20px !important;
  background-color: transparent !important;
  padding: 2px 10px !important;
  color: white;
  width: 92px !important;
  font-size: 13px !important;
  font-family: "Poppins", sans-serif !important;
  align-self: self-end;
  transition: background-color ease-in 0.3s;
}

.price_buy_container>button:hover{
  background-color: #F49B09EB !important;
}

.card_divider_wrapper{
  display: flex;
  align-items: center;
}

.card_divider_absolute{
  position: absolute;
  left: -30px;
  right: -30px;
  transform: translateY(150%);
}

.divider_circle{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
}

.divider_line{
  height: 2px;
  width: 100%;
  background-color: white;
}

@media screen and (max-width: 500px) {
  .bundle_card_wrapper{
    padding: 10px 0;
  }

  .p_b{
    font-size: 14px !important;
  }

  .card_divider_absolute{
    transform: translateY(40%);
  }
}
