.investmentPage {
  width: 90%;
  margin: auto;
  margin-top: 8rem;
  margin-bottom: 4rem;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.investmentPage .i-logo {
  margin: 20px 40px;
  margin-left: auto;
  width: 200px;
}

.investmentPage .secure {
  width: 200px;
  padding: 20px;
  margin-left: auto;
  margin-top: 3rem;
}
.investment {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px;
}
.investment h1 {
  font-family: Agrandir;
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: -0.019em;
  color: #082e88;
}
.investment p {
  font-family: Agrandir;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.006em;
  margin-bottom: 2rem;
}
.investment form {
  display: flex;
  flex-direction: column;
  width: 60%;
  /* border: 1px solid; */
}
.investment form label {
  display: flex;
  justify-content: space-between;
  border: none;
  position: relative;
}
.investment form label input {
  width: 70%;
  outline: none;
  padding-left: 5px;
}
.investment form label .i-visible-blind{
  position: absolute;
  right: 3%;
  opacity: .7;
}
.investment form label select {
  width: 70%;
  border: 1px solid gainsboro;
  outline: none;
  padding: 5px;
}
#dob {
  display: flex;
  margin-bottom: -15px;
  padding-left: 10px;
}
#dob p {
  width: 30%;
}
#dob span {
  border: 1px solid gray;
  height: fit-content;
  border-radius: 5px;
  min-width: 70%;
  font-family: "Times New Roman", Times, serif;
}
.datePicker{
    border: none;
    width: 100%;
    border: 1px solid;
}
.datePicker input {
  border: none;
  width: fit-content;
  padding: 0;
  outline: none;
}
.ndpr{
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.ndpr input{
  margin: 5px;
}
.ndpr small{
  color: black !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.investment form small {
  width: fit-content;
  margin-left: auto;
  margin-right: 4rem;
  font-family: Agrandir;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #ff2424;
}
.investment form label input::placeholder {
  font-size: 12px;
  color: #0e8bff;
  font-family: Agrandir;
}
.investment .btns {
  display: flex;
  gap: 10px;
  width: 70%;
  font-size: 14px;
  padding: 10px;
  margin-top: 3rem;
  margin-left: auto;
}
.investment .btns button:nth-child(1) {
  background-color: #0051ff;
  border: none;
}
.investment .btns button:nth-child(2) {
  background-color: #f21010;
  border: none;
}
.investment section {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.investment section img {
  width: 300px;
  margin: auto;
}

@media (max-width: 620px) {
  .investmentPage {
    width: 100%;
    margin-top: 3rem;
  }
  .investmentPage .i-logo {
    margin: 20px 20px;
    margin-left: auto;
    width: 120px;
  }
  .investment {
    flex-direction: column;
  }
  .investment h1 {
    font-size: 22px;
    font-weight: 900;
  }
  .investment form {
    width: 100%;
  }
  .investment form label {
    flex-direction: column;
    padding-left: 10px;
    justify-content: flex-start;
  }
  .investment form label input {
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
    outline: none;
    padding: 5px;
  }
  .investment form label select {
    width: 100%;
  }
  .investment form small {
    font-size: 10px;
    margin-right: 0;
  }
  #dob {
    flex-direction: column;
    gap: 0;
    margin-block: 10px;
    width: 95%;
  }
  #dob p{
    width: 90%;
  }
  #dob span{
    margin-top: -2rem;
  min-width: 100%;

  }
  .investment #investAmts {
    margin-top: -3rem;
    margin-left: 1rem;
  }
  .investment .btns {
    width: 100%;
    font-size: 12px;
    padding: 5px;
  }
  .investment section {
    width: 100%;
    margin-top: 2rem;
  }
  .investment section img {
    width: 200px;
    transform: translateX(10%);
  }
}
