.mainDiv {
  position: relative;
  margin: auto;
  margin-top: 60px;
  max-width: 350px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  color: #333;
}

.h2tag {
  width: 239px;
  height: 40px;
  left: 71px;
  right: 83px;
  top: 264px;
  bottom: 548px;
}

.enterPinDiv {
  width: 234px;
  height: 90px;
  left: 30px;
  right: -30px;
  top: 363px;
  bottom: -285px;
}

.pinInput {
  width: 250px;
  height: 50px;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.pinInputContainer {
  margin-bottom: 30px;
}

.eyeIcon2 {
  margin-left: 250px;
  margin-top: -60px;
}

.errorMessage {
  color: red;
  margin-bottom: 12px;
  margin-left: 10px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}

.buttonSubmit,
.buttonCancel {
  display: inline-block;
  padding: 8px 8px;
  margin: 5px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 25px;
  margin-right: 35px;
}

.buttonSubmit {
  background-color: #4caf50;
  color: white;
}

.buttonCancel {
  background-color: #f44336;
  color: white;
}

.ptag {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  color: rgb(10, 3, 45);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -5%;
  text-align: left;
  margin-bottom: 50px;
  margin-left: 24px;
}

.ptag p {
  font-size: 20px;
  margin-top: 20px;
}

.ptag p span {
  color: #4caf50;
  cursor: pointer;
}

h2 {
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
  margin-left: 23px;
}

.cancelIcon {
  position: absolute;
  top: 10px;
  right: 40px; /* Changed from left to right */
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  margin-right: -20px;
}

@media only screen and (max-width: 768px) {
  .modal {
    margin-top: 350px;
    margin-left: 170px;

    max-width: 350px;
  }

  h1 {
    color: rgb(10, 3, 45);
    width: 80%;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -5%;
    text-align: center;
    margin-bottom: 50px;
    /* margin-left: 15px; */
  }
}
