

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 10px;
  background: rgba(169, 185, 180, 0.7);
}

.wrapper {
  padding-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
}

.main {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
  max-width: 90%; /* Ensures responsiveness */
  width: 400px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.text h2 {
  color: red;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.text p {
  color: black;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  max-width: 300px;
  overflow-wrap: break-word;
}

.done {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  justify-items: flex-end;
  padding-left: 170px;
  gap: 20px; 
}

.doneButton {
  background: none;
  color: rgb(15, 63, 178);
  border: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
}

.errorMessage {
  color: red;
  font-size: 20px;
  font-family: Poppins;
  text-align: center;
}

.cancelIconHeader {
  font-size: 60px;
  color: red;
}

.cancelIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: rgb(19, 9, 9);
}

/* Responsive Styles */

/* For screens larger than 1024px */
@media only screen and (min-width: 1025px) {
  .wrapper{
    padding-left: 80px;
  }
  .main {
    width: 500px; 
  }

  .text h2 {
    font-size: 28px; 
  }

  .text p {
    font-size: 22px; /* Increase font size */
    max-width: 350px; /* Increase max width */
  }

  .doneButton {
    font-size: 20px;
  }

  .cancelIcon {
    font-size: 25px;
  }

  .cancelIconHeader {
    font-size: 70px;
  }
}

/* For screens between 769px and 1024px (tablets) */
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .wrapper {
    padding-top: 150px;
  }
  .main {
    width: 100%; /* Make the width 80% of the screen */
  }

  .text h2 {
    font-size: 22px; /* Slightly reduce font size */
  }

  .text p {
    font-size: 18px; /* Reduce font size */
    max-width: 280px;
  }

  .doneButton {
    font-size: 16px;
  }

  .cancelIcon {
    font-size: 18px;
  }

  .cancelIconHeader {
    font-size: 55px;
  }
}

/* For screens smaller than 768px (phones) */
@media only screen and (max-width: 768px) {
  .wrapper {
    padding-top: 50px;
   /* Reduce top padding */
  }

  .main {
    width: 100%; /* Use most of the screen width */
    padding: 1rem; /* Reduce padding */
  }

  .text h2 {
    font-size: 20px; /* Reduce font size */
  }

  .text p {
    font-size: 16px; /* Reduce font size */
    max-width: 250px;
  }

  .done {
    padding-left: 130px; /* Adjust padding for smaller screens */
  }

  .doneButton {
    font-size: 14px;
  }

  .cancelIcon {
    font-size: 16px;
  }

  .cancelIconHeader {
    font-size: 50px;
  }
}
