.image-item img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: 6px;
}
.image-item {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #eaeffe;
  padding: 0 !important;
  margin-right: 0px !important;
}
.file-upload-div {
  width: 100%;
  /* height: 55px; */
  display: flex !important;
  padding-left: 5px;
  border: 1px solid #000000;
  border-radius: 10px;
  /* margin-top: 20px; */
  margin-bottom: 0;
}
.file-upload-div h5 {
  font-family: AgrandirBold;
  font-weight: 900;
  color: #000000;
  margin-top: 11px;
  flex-grow: 1;
  text-align: left !important;
}
.file-upload-arrow {
  margin-top: 10px;
}
.file-upload-container {
  margin-top: 0px !important;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
.file-btn {
  width: 100% !important;
  padding: 0px !important;
  display: flex;
}
.camera-btn {
  /* background: grey; */
  position: relative;

  z-index: 10;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-left: -160px;
  margin-top: 10px !important;
}
.camera-btn img {
  width: 65%;
}
@media screen and (max-width: 620px) {
  .camera-btn {
    margin-left: 150px;
    width: 100%;
  }
  .camera-btn img {
    width: 45%;
  }
}
.file-upload-input {
  /*                                                        */
  margin-bottom: 0 !important;
}
.upload__image-wrapper {
  box-shadow: none !important;
  border: 0 !important;
  background: #fff !important;
  padding-bottom: 0 !important;
}
.file-upload-input :hover {
  box-shadow: none !important;
  border: 0 !important;
  background: #fff !important;
  padding-bottom: 0 !important;
}
.hint {
  color: rgb(214, 209, 209);
  font-family: AgrandirBold;
  margin-right: 80px;
  margin-top: 15px;
  flex-grow: 1;
}
.hint2 {
  color: red;
  font-family: AgrandirBold;
  margin-left: 10px;
  font-size: 10px;
}
.file-title {
  font-family: AgrandirBold;
}
.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 100px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#top {
  top: -40px;
  left: -230%;
}
.hover-text {
  position: relative;
  display: inline-block;
  /* margin: 40px; */
  font-family: AgrandirBold;
  text-align: center;
}
