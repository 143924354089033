.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
  /* margin-top: 10px; */
  margin-right: 10px;
}
.search-box i {
  color: #fff;
}
.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  /* background-color: #10133f; */
  background: transparent;
  padding-right: 40px;
  color: #fff;
}
.input-search::placeholder {
  color: #fff !important;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #ffffff;
  background-color: transparent;
  pointer-events: painted;
}
.btn-search:focus ~ .input-search {
  width: 200px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: solid 1px rgb(70, 66, 66);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
