.transaction-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
}
.transaction-tabs li {
  padding: 14px;
  display: flex;
  color: #78778b;
  cursor: pointer;
}
.transaction-tabs li:active {
  color: white;
}
.active {
  font-weight: 600;
  color: white !important;
  border-bottom: 2px solid #329521 !important;
}
.allTransactions,
.recent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #0b0230;
  color: white;
  padding: 32px;
  /* margin-top: 4rem; */
  margin-left: 0px;
  border-radius: 10px;
}
.recent {
  margin-top: 1.5rem;
  border-radius: 20px;
}
.allTransactionCon {
  /* margin-top: 8rem; */
  padding: 10px 2rem;
  overflow: auto;
}
.allTransactions header,
.recent header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.allTransactions header h1,
.recent header h1 {
  color: #fff;
  font-size: 22px;
  font-family: "Agrandir-bold";
  margin: 0;
}
.recent header h1 {
  font-size: 20px;
  font-family: "PoppinsRegular";
  font-weight: 600;
}
.allTransactions header button,
.recent header button {
  width: fit-content;
  border: none;
  background-color: transparent;
  /* color: #329521; */
  font-weight: 600;
}
.allTransactions header button a,
.recent header button a {
  text-decoration: none;
  color: inherit;
  margin-right: 20px;
}
.allTransactions main,
.recent main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
  width: 100%;
}
.recent main {
  gap: 0.5rem;
}
.allTransactions main ul,
.recent main ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
}
.recent main ul {
  gap: 0.5rem;
}
.allTransactions main .allTransactions-head,
.recent .allTransactions-head {
  color: #aeaeae;
  font-family: "PoppinsRegular";
}
.recent main .allTransactions-head {
  padding-bottom: 10px;
}
.allTransactions main .allTransactionBody,
.recent main .allTransactionsBody {
  font-weight: 500;
  font-family: "PoppinsRegular";
  padding-bottom: 10px;
  border-bottom: 1px solid #aeaeae;
}
.allTransactions main .allTransactionBody li {
  font-weight: 500;
  font-family: "PoppinsRegular";
}
.allTransactions main .allTransactionBody li:last-child {
  width: 90px;
  cursor: pointer;
}
.allTransactions main ul li {
  width: calc(100% / 5);
  font-size: 12px !important;
  padding: 5px 10px;
  overflow-x: auto;
}
.recent main ul li {
  width: calc(100% / 6);
  padding: 0;
  font-size: 12px !important;
}
.recent main ul li:first-child {
  width: 40%;
  flex-grow: 1;
}
.recent main ul li:nth-child(3) {
  width: 30%;
}
.allTransactions h3,
.recent h3 {
  text-align: center;
  font-family: "Agrandir-bold";
  font-size: 16px;
}
.recent h3 {
  font-family: "PoppinsRegular";
}
/*----TRANSACTION RECEIPT MODAL*/
.transaction-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(11, 2, 48, 0.9);
  z-index: 100;
  overflow: auto;
}
.transaction-modal {
  color: #082e88;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.transaction-modal .close-button {
  /* color: #0b0230; */
  color: white;
  display: block;
  position: absolute;
  top: -40px;
  right: -30px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
}
.transaction-modal h3 {
  padding-top: 10px;
  font-weight: 600;
}
.transaction-modal li {
  color: #082e88;
  width: 100%;
  text-align: center;
  display: block;
}
.receipt-amount {
  font-size: 26px !important;
  font-weight: 700;
}
.receipt-status {
  font-size: 26px !important;
  text-transform: uppercase;
  font-weight: 500;
}
.receipt-date {
  color: #78778b !important;
  font-weight: 600;
  padding-bottom: 15px;
}

.transaction-details {
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
}
.transaction-details tr td:first-child {
  color: gray;
}
td {
  color: #082e88;
  font-weight: 600;
  padding: 10px;
  font-weight: 600;
}
.transaction-support {
  padding-bottom: 10px;
}
.transaction-support h3 {
  color: #082e88;
  font-weight: bolder;
  font-size: 20px;
  text-align: center;
}
.transaction-support span {
  color: #082e88;
  font-weight: 600;
}
.transaction-support span + span {
  padding-left: 30px;
}
.transaction-footer {
  background-color: #082e88;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  padding: 15px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 10px 0 0;
  justify-content: flex-end;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}
.pagination-controls > label {
  color: #fff;
}

.pagination-controls > select,
option {
  color: #082e88;
}
.pagination-controls > select option:hover {
  background-color: #082e88;
}

.pagination li a {
  padding: 5px 10px;
  border: 1px solid #ddd;
  color: #f79e1b;
  text-decoration: none;
}
.pagination li a:not([href]):not([class]) {
  color: #ccc;
}
.pagination li.active a {
  background-color: #082e88;
  color: white !important;
  border: 1px solid #082e88;
}

.pagination li.disabled a {
  color: #ccc;
  cursor: not-allowed;
}

#fundingHistory-head li,
#fundingHistory-body li {
  width: calc(100% / 4);
}
@media screen and (max-width: 620px) {
  .recent {
    display: none;
  }
  .allTransactionCon {
    margin-top: 2rem;
    padding: 0;
  }
  .allTransactions {
    margin-left: 0;
    width: 100%;
    padding: 16px;
    border-radius: 10px;
    gap: 1rem;
    color: #fff !important;
  }
  .allTransactions header div small {
    display: none;
  }
  .allTransactions header h1 {
    font-size: 16px;
  }

  .allTransactions main ul {
    justify-content: space-between;
  }
  .debit-type {
    color: #ff0000;
  }
  .credit-type {
    color: #329521;
  }

  .allTransactions-head li:nth-child(3),
  .allTransactions-head li:nth-child(5) {
    display: none;
  }

  .allTransactionBody li:nth-child(3),
  .allTransactionBody li:nth-child(5) {
    display: none;
  }
  .allTransactionsBody li:nth-child(3),
  .allTransactionsBody li:nth-child(5) {
    display: none;
  }
  .recent header h1 {
    font-size: 14px;
    font-family: "PoppinsRegular";
    font-weight: 600;
  }

  .transaction-tabs {
    padding-left: 0 !important;
  }
}
