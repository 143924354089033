.step_wrapper {
  display: flex;
  padding: 10px 0;
  position: relative;
  z-index: 1;
}

.step_item {
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.step_item + .step_item:after{
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  background: #0051FF;
  width: 100%;
  height: 2px;
  transform: translateX(-50%);
  z-index: -10;
}

.step_container_out{
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #0051FF;
  margin: 0 auto;
  position: relative;
  z-index:10;
  color: transparent;
}

.step_ball{
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.step_ball.white_step_ball{
  background-color: white;
}

.step_item .step_label{
  font-size: 10px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  color: #0F3FB2;
}
