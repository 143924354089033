.background {
  background-color: white;
}

.mainDiv {

  margin: auto;
  margin-top: 150px;
  margin-bottom: 20px;
  width: 50%;
  max-width: 500px;
  background-color: white;
  padding: 1rem;
 
  color: #333;
  position: relative;
  }
  .backup{
    padding-top: 25px;
    padding-bottom:25px ;
  }


  .infoTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .infoTable td {
    padding: 10px;
    /* border-bottom: 1px solid #ddd; */
  }

  .infoTable td:first-child {
    font-weight: 300;
    color: #0f3fb2;
  }

  .infoTable td:last-child {
    font-weight: 300;
    color: #979797;
  }

  .infoTable tr:last-child td {
    border-bottom: none;
  }

.logoDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
  margin: 0;
  }
  .innerLogoDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-top: 50px;


  }
  .logo{
    display: flex;
    flex-direction: row;
    gap: 70px;
  }
  .transaction {
    font-size: 25px;
    font-family: Poppins;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0f3fb2; /* Ensure this color code is correct */
    margin: 0;
    padding-top: 5px;
  }

  .transactionMessage {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    color: #0f3fb2;
    font-weight: bold;

  }


  .time{
    display: flex;
    font-size: 25px;
    font-family: Poppins;
    margin-bottom: 10px;
    color:  #979797;;
    margin: 0;
  padding-top: 5px;

  }

  .tableLine {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 50px;
    height: 2px;
    background-color: #979797;
    margin-top: 5px;
  }


  .supportDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .supportDiv3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0;
    padding-top: 20px;
  }

  .support3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    color: #979797;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* padding-top: 5px; */
    padding-bottom: 20px;
    padding-right: 20px;
  }

  .supportSpan {
    display: block;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
    color: #0f3fb2;
    text-align: center;
  }



  .support {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    color: #979797;
    text-align: center;

  }

  .support2 {
    margin: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    color: #0f3fb2;
    text-align: center;

  }

.paymentStatus {
  padding-top: 15px;
  font-size: 20px;
 font-weight: bold;
  font-display: block;

  display: inline-block;
}


.failedStatus {
  color: red;
}


.successfulStatus {
  color: #18B30A;
}

.downloadButton {
margin-top: -10px;
  padding: 10px 20px;
  font-size: 16px; 
  color: #fff;
  background-color: #0f3fb2; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  text-align: center; 
  margin-bottom: 10px;

}

.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


.cancelIconDiv {
  position: absolute;
  margin-top: 150px;
  right: 20px;
  padding-right: 500px;
}

.cancelIcon {
  font-size: 24px;
  color: red;
  cursor: pointer;
}
