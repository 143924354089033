.referral-container {
  /* width: 50%;
    margin: auto; */
  /* margin-top: 8rem; */
  margin-bottom: 2rem;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
}

.referral-container .tabs {
  display: flex;
  align-items: center;
  color: #fff;
  gap: 3rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid grey;
}
.tabs > li {
  padding: 14px;
  display: flex;
  cursor: pointer;
}

.referral-list {
  color: white;
}
.referral {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 4rem;
  padding-top: 3rem;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
}

.referral > * {
  width: 100%;
  text-align: center;
}

.referral-intro {
  padding: 15px 0;
  background-color: #100247;
  border-radius: 10px;
}
.referral-intro > h3 {
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 400;
}
.referral-intro > .points {
  padding-top: 2px;
  font-size: 16px;
  font-family: "Montserrat" !important;
  font-weight: 500;
}
.points > span {
  text-decoration: line-through;
}
.referral-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #100247;
  border-radius: 10px;
  margin-bottom: 1rem;
  /* font-weight: 400; */
}
.claim > .input-field > input {
  /* width: 50px !important; */
  background-color: transparent;
  border: none;
  color: white;
  padding-left: 5px;
}
.claim > .input-field > label {
  font-family: "Montserrat" !important;
  font-weight: 500;
  color: white;
  margin-left: -10rem;
  padding: 0 !important ;
}

.claim > button {
  width: 110px !important;
  background-color: orange;
  border: none;
  transition: 0.5s;
}
.claim > button:hover {
  background-color: #100247;
}

.claim > img {
  width: 40px;
}

.referral-info {
  font-size: 15px;
  font-family: "Montserrat";
}
.message {
  background-color: #100247;
  margin-top: -16rem !important;
  padding: 0 2rem 2rem;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  animation: slideIn 0.5s ease-in-out;
}
.error .close-button,
.success .close-button {
  color: black;
  display: block;
  padding: 1rem;
  text-align: right;
  transition: 0.5s;
}
.success .close-button {
  color: white;
}
.success .close-button:hover {
  color: orange;
}

.error .close-button:hover {
  color: red;
}
.error {
  background-color: #fff;
}
.error img {
  width: 50px;
  /* height: 50px; */
  display: block;
  margin: auto;
  padding-bottom: 2rem;
}
.referral-list > ul > li {
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
}
/* Slide-in animation */
@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    display: flex;
    opacity: 1;
  }
}

@media (max-width: 620px) {
  .referral-container {
    margin-top: 2rem;
    padding: 10px;
  }
  .referral {
    width: 95%;
    padding: 2rem;
  }

  /* .claim > button {
    width: 120px !important;
  } */
}
@media (max-width: 1024px) and (min-width: 770px) {
  .referral {
    width: 70%;
  }
}
@media (max-width: 771px) and (min-width: 621px) {
  .referral {
    width: 80%;
  }
}
