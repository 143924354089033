.settingsPage .settings-page-container {
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 1rem 0;
}
.settings-container {
  background-color: white;
  color: #0e093f;
  width: 500px;
  margin: auto;
  margin-top: 1.5rem;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  border-radius: 5px;
  padding: 30px;
  position: relative;
  transition: opacity 300ms ease, transform 300ms ease;
  opacity: 1;
  transform: translateX(0);
}
/* TRANSITIONS */
/* .fade-out {
  opacity: 0;
  transform: translateX(10px);
} */

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
}
.settings-header span {
  font-size: 16px;
  font-weight: 500;
}
.settings-header-back-icon {
  cursor: pointer;
}
.settings-user-info {
  text-align: center;
}
.settings-user-info .user-picture {
  border: 1px solid #ebebeb;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin: auto;
  position: relative;
}
.user-picture .passport {
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.edit-icon {
  position: absolute;
  right: -5px;
  bottom: 5px;
}
.settings-user-info h2 {
  color: #181d27;
  font-size: 18px;
  margin: 0;
  margin-top: 10px;
  line-height: 30px;
}
.padding-bottom {
  padding-bottom: 5px;
}
.settings-user-info p {
  color: #0f3fb2;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-weight: 600;
  margin: 0 !important;
  padding: 5px 0 10px !important;
}
.settings-cards-holder {
  width: 95%;
  margin: auto;
}
.settings-footer {
  font-family: Poppins;
  color: #979797;
  text-align: center;
  padding: 17px 0;
  font-weight: 500;
}
/* Settings Card */
.settings-card-container {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 10px;
  margin-bottom: 10px;
}
.card-icon-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.settings-card-container .img-bcg {
  height: 35px;
  width: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardInfo h3 {
  font-size: 16px;
  font-weight: 600;
  color: #0f3fb2;
  margin: 0 !important;
  line-height: 30px;
}
.cardInfo span {
  font-size: 12px;
  color: #6c6c6c;
  display: block;
}
.go-to-icon {
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 620px) {
  .settings-container {
    width: 100%;
  }
}
@media screen and (max-width: 510px) {
  .settings-cards-holder {
    width: 100%;
  }
  .settings-user-info h2,
  .settings-user-info p {
    font-size: calc(18px - 1vw) !important;
  }
  .cardInfo h3 {
    font-size: calc(16px - 0.6vw);
  }
  .cardInfo span {
    font-size: calc(12px - 1vw);
  }
}
@media screen and (max-width: 440px) {
  .elipsis {
    max-width: calc(190px - 0.2rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 374px) {
  .cardInfo h3 {
    font-size: calc(16px - 1vw);
  }
  .elipsis {
    max-width: calc(150px - 0.2rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
