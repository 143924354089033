/* Base Styles */
.modal {
  position: fixed;
  margin-top: 350px;
  margin-left: 600px;

  /* left: 50%; */
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 550px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  color: #0F3FB2;
  font-family: Poppins;
}

.background {
  padding: 20px;
  margin-top: 150px;
  position: relative;
  border-radius: 10px;
  background: white;
}

.input::placeholder {
  font-size: 12px;
  color: rgb(130, 130, 130);
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 90%;
  position: absolute;
}

.modalTitle {
  flex: 1;
  text-align: center;
  font-size: 20px;
  margin: 0;
}

.cancelIcon {
  cursor: pointer;
  margin-right: auto;
}

.dataPlanWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.topCase {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  padding: 10px;
  background-color: #0F3FB2;
  height: 60px;
  width: 100%;
  z-index: 2;
  text-align: center;
  line-height: 24px;
}

.topCase p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.iconWrapper {
  display: flex;
  flex-direction: column;
}

.iconDesign {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  gap: 10px;
  margin: 0;
}

.lowerCase {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 0 0 10px 10px;
  padding: 10px;
  background-color: #0F3FB2;
  width: 100%;
  padding-bottom: 20px;
  z-index: 2;
}

.eclipe {
  display: flex;
  justify-self: flex-start;
  align-self: flex-start;
  z-index: 3;
  margin-bottom: -10px;
  margin-top: -10px;
}

.eclipeWrapper {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: flex-start;
  align-content: flex-start;
}

.networksRow {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
  margin-top: 80px;
}

.networkLogo {
  width: 60px;
  cursor: pointer;
  opacity: 0.6;
  margin-top: -30px;
}

.networkLogo.selected {
  opacity: 1;
  border: 2px solid #4caf50;
  border-radius: 5px;
}

.formGroup {
  margin-bottom: 10px;
  margin-top: -30px;
  margin-left: 7%;
  margin-right: 5%;
}

.formLabel {
  display: flex;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #0F3FB2;
  margin-left: -1px;
}

.input {
  width: 60%;
  padding: 8px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid #ccc;
  color: #333;
  font-family: Poppins;
}

.inputActive {
  border-color: #0F3FB2;
}

.tabs {
  border-bottom: 2px solid #ccc;
  margin-bottom: 30px;
}

.tabsInner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 92%;
}

.tab {
  border-top: 2px solid #ccc;
  padding: 6px;
  border: none;
  background: none;
  font-size: 14px;
  cursor: pointer;
  margin-left: 7.5%;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.selectedTab {
  color: #0F3FB2;
  background-color: #0F3FB2;
  color: white;
  border-bottom: 2px solid #0F3FB2;
  border-radius: 40px;
}

.deselectedTab {
  color: #666;
}

.buyNowDiv {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-left: 10px;
  padding-top: 40px;
}
.buyNowDiv2{
  display: none;
  align-content: center;
  justify-content: center;
  padding-left: 10px;
  padding-top: 40px;
  

}
.buyNowButton {
  background: none;
  border: 3px solid #F49B09EB;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  font-family: Poppins;
  font-size: 12px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1px;
}

.dataPlansRow {
  display: flex;
  flex-direction: column;
  max-height: 280px;
  overflow-y: auto;
  gap: 0.5rem;
  margin-left: 3%;
  margin-right: -0.8%;
}

.dataPlansRow::-webkit-scrollbar {
  visibility: hidden;
}

.dataPlanButton {
  background: none;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px 5px;
  cursor: pointer;
  color: #333;
  width: 60%;
  height: 60px;
  font-family: Poppins;
}

.selectedDataPlan {
  border-color: #0F3FB2;
  color: #333;
}

.message {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
  font-size: 14px;
  font-family: Poppins;
}

.errorMessage {
  color: red;
  font-size: 14px;
  font-family: Poppins;
}

.data .buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.buttonSubmit,
.buttonCancel {
  background-color: #0F3FB2;
  color: white;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  font-family: Poppins;
}

.line {
  padding-top: 40px;
}

.buttonCancel {
  background-color: #f44336;
}

.lineImage {
  transform: rotate(90deg);
  transform-origin: center;
}

.buttonCancel {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}

.cancelButton {
  color: #f44336;
  border: none;
  cursor: pointer;
}

.iconDesign p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}

.iconDesign span {
  display: flex;
  flex-direction: column;
}

.priceIcon {
  color: white;
  font-size: 23px;
}

.validity,
.price {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #FFFFFF;
}

.display {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-left: 18px;
}

.theWrapper {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  .modal {

  margin-top: 350px;
  margin-left: 170px;

  max-width: 350px;
  }
  .buyNowDiv2{
    display: flex;
  }
  .buyNowDiv{
    display: none;
  }
  .header {
    padding: 15px;
    width: calc(100% + 30px);
    margin: -15px;
  }

  .formLabel {
    font-size: 16px;
    margin-left: -10px;
  }

  .topCase {
    width: 120%;
    height: 50%;
  }

  .lowerCase {
    width: 120%;

   
  }

  .input {
    width: 80%;
    padding: 6px;
    margin-left: -10px;
  }

  .tab {
    font-size: 14px;
    padding: 8px;
  }

  .selectedTab {
    border-radius: 30px;
  }

  .dataPlanButton {
    padding: 8px 4px;
    height: 50px;
  }

  .iconDesign p {
    font-size: 12px;
  }

  .validity,
  .price {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .modal {
    margin-left: 400px;
   
  }
}