.kyc_field_verify_comp_wrapper{
  position: fixed;
  width: 100%;
  height: 100svh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify_otp_confirm_wrapper{
  background-color: white;
  padding: 30px 40px;
  border-radius: 8px !important;
}

.kyc_verify_otp_container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kyc_verify_otp_container .verification{
  color: #2E67EE !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 0 !important;
  margin: 15px 0;
  text-align: center !important;
}

.kyc_verify_otp_container .code_msg{
  color: #979797 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  padding: 0 !important;
  text-align: center !important;
}

.kyc_verify_otp_container input{
  margin: 15px auto !important;
}

.kyc_verify_otp_container .receive_code_msg{
  color: #0051FF !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  padding: 0 !important;
  text-align: center !important;
}

.kyc_verify_otp_container .confirm_btn{
  background-color: #0F3FB2;
  border: none !important;
  padding: 10px 0 !important;
  color: white;
  border-radius: 10px;
  width: 100% !important;
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  margin-top: 10px;
}

.kyc_verify_otp_container .otp_field_area{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.kyc_verify_otp_container .otp_field_area input{
  width: 35px !important;
  height: 35px !important;
  background-color: #0051FF;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-align: center !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
}

.kyc_verify_otp_container .otp_field_area input:focus{
  background-color: #F49B09EB;
  outline: 2px solid #0051FF;
}

.verification_status_msg_container{
  display: flex;
  width: 130px;
  flex-direction: column;
  align-items: center;
}

.verification_status_msg_container img{
  width: 58px !important;
}

.verification_status_msg_container p{
  padding: 0 !important;
  margin: 15px 0 !important;
  text-align: center !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  color: #0F3FB2 !important;
  font-weight: 500 !important;
}

.verification_status_msg_container button{
  padding: 0 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  color: white !important;
  font-weight: 500 !important;
  background-color: #0F3FB2 !important;
  border-radius: 10px !important;
  border: none !important;
  width: 100% !important;
  height: 42px !important;
}

.receive_code_msg button{
  border: none;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  color: inherit !important;
  border-radius: 0 !important;
  width: unset !important;
  font-weight: 500;
}
