.kyf-form-container {
  background: #fff;
  height: fit-content;
  box-shadow: 0 0 1px 1px rgb(211, 208, 208);
  padding: 20px 15px;
  border-radius: 10px;
}
.kyf-form-container h3 {
  text-align: center;
  font-family: AgrandirBlackItalic;
  color: #0b0230;
}
.kyc {
  color: #ff0505 !important;
}
.kyc-account-type {
  color: #84632e;
}
.kyf-form-container h4 {
  text-align: center;
  color: #253b80;
  font-family: AgrandirBold;
  font-weight: 700;
}

.css-b62m3t-container {
  border: 2px solid #676666;
  height: 49px !important;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  padding-bottom: 5px;
  /* padding-bottom: 15px; */
}
.country-heading {
  font-family: AgrandirBold;
}
