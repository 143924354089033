* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'Agrandir-Regular';
  src: url('../public/fonts/Agrandir/Agrandir-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Agrandir-Italic';
  src: url('../public/fonts/Agrandir/Agrandir-WideBlackItalic.otf')
    format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Agrandir-Grandheavy';
  src: url('../public/fonts/Agrandir/Agrandir-GrandHeavy.otf')
    format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Agrandir-Bold';
  src: url('../public/fonts/Agrandir/Agrandir-TextBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

:root {
  --hover-color: #2962f2;
  --link-color: #0b0230;
  --font-regular: 'Agrandir-Regular', sans-serif;
  --font-grand: 'Agrandir-Grandheavy', sans-serif;
  --font-bold: 'Agrandir-Bold', sans-serif;
  --font-italic: 'Agrandir-Italic', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}