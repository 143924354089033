p {
  margin: 0;
  font-family: AgrandirBold !important;
}
.logo {
  position: relative;
  width: 120px;
  height: 20px;
  margin: auto;
  /* border: 1px solid #000; */
}
.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border-radius: 20px;
  /* background-color: #0b0230; */
  background-color: #ffff;
  border: 1px solid #000;
  margin: auto;
  margin-top: 5%;
  /* margin-left: 25%; */
  padding: 32px;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  /* padding: 5%; */
  /* border: 1px solid #000; */
}

.reset-password-fields {
  padding: 10px !important;
  width: 100% !important;
}

.reset-password-fields::placeholder {
  font-family: AgrandirBold !important;
  color: #c1bebe !important;
  font-size: 13px;
}

.reset-password-button {
  font-family: AgrandirBold !important;
  padding: 2%;
  width: 100% !important;
  background-color: #2962f2;
  color: #fff;
  justify-content: center;
  font-size: 15px;
  border: none;
}

@media screen and (max-width: 620px) {
  .reset-password-form h1 {
    text-align: center;
    font-family: AgrandirBold !important;
    /* font-weight: bolder; */
    margin-bottom: 2rem;
  }
  .reset-password-container {
    margin-top: 5rem;
    width: 100%;
  }

  .reset-password-button {
    padding: 2%;
    width: 100%;
    background-color: #2962f2;
    color: #fff;
    justify-content: center;
    /* font-size: 32px; */
    border: none;
  }
}
@media (max-width: 1024px) and (min-width: 621px) {
  .reset-password-container {
    width: 50%;
  }
  .logo {
    width: 180px;
    height: 30px;
  }
  .reset-password-form h1 {
    text-align: center;
    font-family: AgrandirBold !important;
    font-weight: bolder;
    margin-bottom: 2rem;
  }
}
