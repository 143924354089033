.mainDiv {
  margin: auto;
  width: 40%;
  padding: 1rem;
  background: white;
  border-radius: 10px;
  color: #333;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1px;

}
.mainDiv2 {
  margin: auto;
  margin-top: 20px;
  width: 40%;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  color: #333;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.networksRow {
  display: flex;
  justify-content: space-around;
  
}

.networkLogo {
  width: 60px;
  cursor: pointer;
  opacity: 0.6;
}

.networkLogo.selected {
  opacity: 1;
  border: 2px solid #4CAF50;
  border-radius: 5px;
}
.formGroup {
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 5%;
  margin-right: 5%;
}

.formLabel {
  display: flex;
  font-size: 18px;
  font-family: Poppins;
  margin-bottom: 10px;
  color: #0F3FB2;
  margin-left: -1px;
}

.formLabelCheckbox {
  font-size: 14px;
  font-family: Poppins;
  margin-bottom: 20px;
  color: rgb(130, 130, 130);
}

.activeText {
  color: #0F3FB2;
}

.formGroup input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
}

.input {
  border: 1px solid #ccc;
}

.inputActive {
  border-color: #0F3FB2;
}

.eyeIcon2 {
  margin-left: 350px;
  margin-top: -35px;
}

.errorMessage {
  color: red;
  margin-top: 30px;
  margin-bottom: 10px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.buttonSubmit,
.buttonCancel {
  display: inline-block;
  padding: 10px 160px;
  margin: 5px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.buttonSubmit {
  background-color: #0F3FB2;
}

.buttonCancel {
  background-color: #DEDADA;
  color :  #000000;

}

.formGroupCheckbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.switch {
  position: relative;
  display: flex;
  width: 60px;
  height: 34px;
  align-items: center;
  margin-right: 5px;
  border: none;
  box-shadow: none;
}
.switchLabel {

  font-size: 14px;
  font-family: Poppins;
  color: rgb(130, 130, 130);
  margin-left: 60px; /* Space between slider and label */
  /* Ensure no default margins or extra spacing */
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  border: none;
  box-shadow: none;
}
.header {


  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 100%;
  margin-bottom: 20px;


  
}

.cancelIcon {
  position: absolute;
  left: 20px; 
  top: 9%; 
  transform: translateY(-50%); 
  cursor: pointer;
}
.input::placeholder {
  font-size: 15px; 
  color:rgb(130, 130, 130);; 
}

.background{
  margin-top: 30px;
}
.modalTitle {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;

}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  border: none;
}

input:checked + .slider {
  background-color: #0F3FB2;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.bundle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0;
  margin: 0;
}

.bundle h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  color: #0F3FB2;
}
.bundleDetails {
  width: 370px;
  height: 107px;
  border-radius: 20px;
  background: rgb(15, 63, 178);
  margin-bottom: 50px;
}

.detailHead {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  padding-left: 20px;
  color: #FFFFFF;
}

.detail {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 130px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;
}

@media only screen and (max-width: 768px) {
  
  .mainDiv {
    width: 90%;
    padding: 1.5rem;
    margin-top: 5px;
    margin-bottom: 5px;
  }
 
  .formLabel {
    font-size: 16px;
  }

  .formGroup input {
    width: 100%; /* Full width for inputs on smaller screens */
  }

  .eyeIcon2 {
    margin-left: 90%;
  }

  .bundleDetails {
    width: 90%; /* Adjust width for smaller screens */
    height: auto;
  }

  .bundle h1 {
    font-size: 18px;
  }

  .detail {
    font-size: 80px; /* Reduced font size */
  }

  .networksRow {
    flex-direction: row; 
    gap: 5px;

  
    
  }

  .networkLogo {
    width: 50px;
  }

  .buttonGroup {
    flex-direction: column;
    align-items: center;
  }

  .buttonSubmit,
  .buttonCancel {
    width: 100%; /* Full width on smaller screens */
    padding: 10px 0; /* Adjust padding for smaller screens */
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .mainDiv {
    margin-top: 30px;
    width: 80%;
  }
 
  .bundleDetails {
    width: 80%;
  }

  .bundle h1 {
    font-size: 20px;
  }

  .detail {
    font-size: 100px; 
  }
  .eyeIcon2 {
    margin-left: 400px;
  }
 
}

@media only screen and (min-width: 1025px) {
  .mainDiv {
    margin-top: 50px;
  }

  .bundle h1 {
    font-size: 22px;
  }

  .detail {
    font-size: 130px;
  }

  .buttonSubmit,
  .buttonCancel {
    width: auto;
  }
 
}