/* .virtual-card-holder {
} */
.virtual-card-holder .virtual-card-details {
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 1rem 0;
}
.virtual-container {
  background-color: white;
  margin: auto;
  width: 500px;
  color: black;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  border-radius: 5px;
}
.all-card-container {
  width: 70%;
  margin: auto;
  padding: 1rem 0;
}
.virtual-card-header {
  display: flex;
  align-items: center;
  gap: 6rem;
  padding-bottom: 2rem;
}
.virtual-card-header span {
  color: #0e093f;
  font-size: 15px;
  font-weight: 600;
}
.virtual-card {
  width: 320px;
  height: 206px;
  background-image: url("../../assets/images/balance-bcg.png");
  border-radius: 20px;
  padding: 1rem;
  margin: auto;
  box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.089);
  color: white;
  margin-bottom: 2rem;
}
.card-top-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-top-details h3 {
  margin: 0 !important;
  font-size: 20px;
}
.card-top-details div {
  font-size: 14px;
  font-weight: 500;
}
.card-pin {
  font-family: kreditBack;
  font-size: 22px;
  text-align: center;
  word-spacing: 10px;
  letter-spacing: 2px;
}
.card-visibility {
  text-align: right;
  padding: 8px 10px;
}
.card-validity {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 6px 10px 8px;
}
.card-date,
.card-cvv {
  font-family: PoppinsRegular !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 0 !important;
}
.card-cvv {
  font-size: 18px !important;
}
.card-name {
  font-weight: 600;
  padding: 0 10px;
}
.virtual-card-information {
  background-color: rgba(135, 182, 248, 0.292);
  border-radius: 10px;
  width: 320px;
  margin: auto;
  padding: 10px 10px 20px;
}
.virtual-card-information > p {
  font-size: 10px !important;
  font-family: PoppinsRegular !important;
  font-weight: 600;
  color: #0f3fb2;
  line-height: 18px;
  padding: 0 !important;
}
.virtual-tools {
  text-align: right;
  padding: 8px 10px 0;
}

.virtual-tools > * {
  color: #0f3fb2;
  margin-right: 12px;
}
.virtual-card-footer {
  color: #979797;
  text-align: center;
  padding: 17px 0;
  font-weight: 500;
}

@media screen and (max-width: 620px) {
  .virtual-container {
    width: 100%;
  }
}
@media screen and (max-width: 510px) {
  .all-card-container {
    width: 100%;
  }
}
