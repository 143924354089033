.internet_options_card_container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.internet_options_container{
  border-radius: 10px;
  background-color: white;
  height: 100%;
  padding: 10px;
}

.option_field_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.option_field_container>label{
  font-family: "Poppins", sans-serif !important;
  font-size:  12px !important;
  color: #0F3FB2 !important;
  font-weight: 500 !important;
  border: none !important;
  padding: 0 !important;
}

.option_field_container>select,
.option_field_container>input{
  font-family: "Poppins", sans-serif !important;
  font-size:  12px !important;
  color: #979797 !important;
  font-weight: 500 !important;
  border: 1px solid #C3C7E5 !important;
  border-radius: 10px;
  padding: 10px 5px !important;
}


.option_field_container>input::placeholder{
  font-family: "Poppins", sans-serif !important;
  font-size:  12px !important;
  color: #979797 !important;
  font-weight: 500 !important;
}

.options_button_group{
  display: flex;
  gap: 3px;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 2px solid #979797 !important;
}

.option_button{
  background-color: transparent !important;
  border: none !important;
  color: #979797 !important;
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  border-radius: 20px !important;
}

.option_button.active{
  background-color: #0F3FB2 !important;
  color: white !important;
}

.option_button.hover_effect:hover{
  background-color: #C3C7E5 !important;
  transition: background-color ease-in 0.5s;
}

.options_content_wrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 350px;
  overflow-y: scroll;
}

.options_content_wrapper::-webkit-scrollbar{
  display: none;
}

.options_content{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;
}

.loader_wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.loader_wrapper img{
  width: 100px;
}
