.general__backdrop{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.general__failed_card{
  width: 350px;
  padding: 50px;
  background-color: white;
  border-radius: 10px;
}

.general__failed_card .img__group{
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
}

.general__failed_card .img__group img{
  width: 70px;
}

.general__failed_card .img__group p{
  color: #FC0019;
  padding: 0 !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}

.general__failed_card .img__group .try_p{
  font-size: 12px !important;
  color: #887C7C !important;
}

.general__failed_card .button__wrapper{
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.general__failed_card .button__wrapper button{
  background-color: transparent !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  width: unset !important;
  color: #0F3FB2 !important;
  font-weight: 600;
  border: none !important;
}
