.invoiceTab {
  padding: 0;
}
.invoiceTab ul {
  display: flex;
  list-style: none;
  gap: 3rem;
}
.invoiceTab ul li a {
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-family: "Agrandir-Bold";
}
.invoiceTab .active {
  border-bottom: 5px solid white;
  padding: 10px;
}
@media (max-width: 620px) {
  .invoiceTab ul {
    gap: 1rem;
  }
  .invoiceTab ul li a {
    font-size: 14px;
  }
  .invoiceTab .active {
    padding: 5px;
    border-bottom: 2px solid white;
  }
}
