.overlay {
  position: absolute;
  top: 15%;
  left: 10px;
  width: 100%;
  height: 85%;
  background: rgba(169, 185, 180, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: 390px;
  margin-left: 20px;
  margin-bottom: 850px;
}

.main {
  background-color: white;
  padding: 2rem;
  border-radius:  10px;
  color: #333;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 20px;
  margin-bottom: 30px;
  position: fixed;
}

.innerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.text p {
  color: rgb(15, 63, 178);
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 5%;
  text-align: center;
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Hides any overflow if the text is too long */
  text-overflow: ellipsis; /* Adds ellipsis if the text is cut off */
}

.done {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 50px;
  margin-left: 315px;
}

.downloadButton {
  background-color: rgb(15, 63, 178);
  color: white;
  border: none;
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.downloadButton:hover {
  background-color: rgb(10, 50, 120);
}

.downloadOptions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 100px;
  align-items: center;
}

.downloadOptions button {
  background-color: rgb(15, 63, 178);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 13px;
  font-family: Poppins;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.downloadOptions button:hover {
  background-color: rgb(10, 50, 120);
}

.cancelIcon {
  position: absolute;
  top: -5px;
  right: 20px;
  font-size: 50px;
  color: red;
  cursor: pointer;
}

.cancelIcon:hover {
  color: rgb(10, 50, 120);
}

.done {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 50px;
}

.doneButton {
  background: none;
  color: rgb(15, 63, 178);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 5%;
}

/* Adjustments for small screens */
@media only screen and (max-width: 600px) {
  .wrapper {
    padding-top: 250px;
    margin-left: 0;
  }

  .main {
    padding: 1rem;
    border-radius: 10px;
    gap: 30px;
  }

  .innerDiv {
    padding-left: 90px;
    padding-right: 90px;
    gap: 30px;
  }

  .text p {
    font-size: 18px;
    text-align: center;
  }

  .done {
    margin-left: 170px;
    align-items: center;
    gap: 30px;
  }

  .doneButton {
    font-size: 18px;
  }

  .cancelIcon {
    top: -5px;
    right: 10px;
    font-size: 40px;
  }

  .downloadOptions {
    margin-left: 0;
    justify-content: center;
  }
}

/* Adjustments for medium screens */
@media only screen and (min-width: 520px) and (max-width: 1020px) {
  .wrapper {
 margin-bottom: 320px;
  }

  .innerDiv {
    /* padding-left: 150px;
    padding-right: 150px; */
    gap: 30px;
  }

  .done {
    margin-left: 300px;
  }
}

/* Adjustments for large screens */
@media only screen and (min-width: 724px) and (max-width: 1280px) {
  .wrapper {
    margin-bottom: 550px;
  }



  .done {
    margin-left: 470px;
  }
}

