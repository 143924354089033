#myFaq {
  margin-top: 5rem;
  font-family: KB-sans-Bold;
  font-size: 20px;
}
.faq_header {
  color: white;
  font-family: AgrandirBold;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 25px;
}
.faqHeader {
  padding: 10px;
}
.faqbody {
  font-family: AgrandirRegular;
  font-size: 16px;
}
@media (max-width: 700px) {
  #myFaq {
    margin-top: 2rem;
  }
}
#myFaq .asst {
  text-align: center;
  color: rgb(84, 84, 156);
  font-family: AgrandiRegular;
  font-size: 16px;
  margin-top: 1rem;
}
#myFaq ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  font-family: Segoe UI;
  font-size: 15px;
  font-weight: 800;
  line-height: 18px;
  margin-top: -2rem;
}
#myFaq ul a {
  text-decoration: none;
  color: white;
}
#myFaq ul li img {
  margin-right: 10px;
  margin-left: 20px;
}
