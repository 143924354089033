.App {
  background-color: #0b0230;
  /* padding-block: 2rem; */
}
@media screen and (max-width: 620px) {
  body {
    padding: 0 1rem;
    background-color: #003033;
  }
}
@font-face {
  font-family: AgrandirBold;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-TextBold.otf);
}
@font-face {
  font-family: AgrandirGrandLight;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-GrandLight.otf);
}
@font-face {
  font-family: AgrandirWideLight;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-WideLight.otf);
}

@font-face {
  font-family: AgrandirHeavy;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-GrandHeavy.otf);
}
@font-face {
  font-family: AgrandirRegular;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-Regular.otf);
}
@font-face {
  font-family: AgrandirTight;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-Tight.otf);
}
@font-face {
  font-family: AgrandirBlackItalic;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-WideBlackItalic.otf);
}
@font-face {
  font-family: AgrandirTinItalic;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-ThinItalic.otf);
}
@font-face {
  font-family: AgrandirNarrow;
  src: url(./assets/fonts/Agrandir-Font/Agrandir-Narrow.otf);
}

/*kumns sans font*/

@font-face {
  font-family: KB-sans-Bold;
  src: url(./assets/fonts/kumbh-sans/KumbhSans-Bold.ttf);
}
@font-face {
  font-family: KB-sans-Regular;
  src: url(./assets/fonts/kumbh-sans/KumbhSans-Regular.ttf);
}

/*kredit font */
@font-face {
  font-family: kredit;
  /* src: url(./assets/fonts/kredit/kredit.ttf); */
  src: url(./assets/fonts/kredit/kreditfront.ttf);
}
@font-face {
  font-family: kreditBack;
  /* src: url(./assets/fonts/kredit/kredit.ttf); */
  src: url(./assets/fonts/kredit/kredit\ back.ttf);
}
/*Montserrat font */
@font-face {
  font-family: MontserratB;
  src: url(./assets/fonts/montserrat/Montserrat-Bold.ttf);
}
@font-face {
  font-family: MontserratR;
  src: url(./assets/fonts/montserrat/Montserrat-Regular.ttf);
}
/*poppins font */
@font-face {
  font-family: PoppinsRegular;
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: PoppinsBold;
  src: url(./assets/fonts/Poppins/Poppins-Bold.ttf);
}

.rm_scroll::-webkit-scrollbar {
  visibility: hidden;
}

/* Pagination styling */
#table_custom_pagination_container {
  display: flex;
  justify-content: space-between;
  margin: 2rem;
}

#table_custom_pagination_container .pagination-buttons {
  display: flex;
  gap: 5px;
}

.pagination-buttons button {
  width: 90px !important;
  height: unset !important;
}

#table_custom_pagination_container span {
  display: flex;
  align-items: center;
  color: white;
}

@media screen and (min-width: 620px) {
  .flexy {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  #table_custom_pagination_container {
    flex-direction: column;
    gap: 10px;
    margin: 1rem;
  }

  ul.pagination {
    justify-content: flex-start !important;
  }
}
