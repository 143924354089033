#kyc_verification_modal_backdrop{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100svh;
  z-index: 30000;
}

.kyc_verification_modal_wrapper{
  background-color: white;
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 50px 40px;
  border-radius: 10px;
}

.kyc_verification_modal_wrapper p{
  padding: 0 !important;
  color: #0F3FB2;
  font-size: 12px !important;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}

.kyc_verification_confirm_btns{
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.kyc_verification_confirm_btns button{
  width: 127px;
  border: none !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}

.kyc_verification_confirm_btns .proceed_verification_btn{
  background-color: #0F3FB2 !important;
}

.kyc_verification_confirm_btns .cancel_verification_btn{
  background-color: #000000 !important;
}
