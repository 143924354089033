.chatus-modal-container {
  background-color: #000000dd;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatus-modal {
  background-color: white;
  width: 500px;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 10px 15px -3px rgba(255, 254, 254, 0.333),
    0 4px 6px -4px rgba(255, 254, 254, 0.326);
  position: relative;
  transition: all 300ms ease;
}
.chatus-modal-section {
  border: 1.5px solid #ebebeb;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.chatus-modal-icon {
  background-color: #18b30a;
  flex-shrink: 0;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatus-modal-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.chatus-modal-container .chatus-modal-title {
  color: #0e093f;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  text-align: center;
  margin: 0 !important;
  padding-bottom: 24px;
}
.social-icons {
  display: flex;
  gap: 10px;
  align-items: center;
}
.chatus-modal-button p {
  width: 150px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
  color: #0f3fb2;
  font-size: 12px !important;
  font-family: Poppins !important;
  padding: 0 !important;
}
.chatus-modal-button button {
  width: auto;
  background-color: #3ad4a7;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}
.chatus-modal-mailus {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.chatus-modal-mail h3 {
  font-size: 16px;
  font-weight: 600;
  color: #0f3fb2;
  margin: 0 !important;
  line-height: 20px;
}
.chatus-modal-mail span {
  font-size: 14px;
  color: #6c6c6c;
  line-height: 20px;
}
.chatus-modal-mail p {
  border: none;
  border-radius: 5px;
  padding: 5px;
  color: #55bbb2;
  font-size: 12px !important;
  font-family: Poppins !important;
  padding: 0 !important;
}
.chatus-modal-form h2 {
  color: #0f3fb2;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
  margin: 0 !important;
}

.chatus-input-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.chatus-input-container .name,
.chatus-input-container .email_con {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.chatus-input-container input {
  padding: 10px;
  background-color: transparent;
  border: 1.5px solid #c3c7e5;
  border-radius: 12px;
  font-size: 12px;
  outline: none;
}
.chatus-input-container input::placeholder,
.textarea-container textarea::placeholder {
  font-size: 12px;
  font-family: Poppins;
  color: #979797 !important;
}
.chatus-input-container .valid {
  font-size: 12px;
  margin-top: 0.25rem;
}
.chatus-select-option {
  font-size: 12px;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  background-color: transparent;
  margin: 10px 0;
  border: 1.5px solid #c3c7e5;
}
.textarea-container {
  width: 100%;
}
.textarea-container textarea {
  padding: 10px;
  font-size: 12px;
  width: 100%;
  border-radius: 12px;
  border: 1.5px solid #c3c7e5;
  background-color: transparent;
}
.submitCon {
  width: 100%;
  text-align: right;
}
.submitCon .submit-button {
  width: 100px;
  border-radius: 30px;
  background-color: black;
}

@media screen and (max-width: 620px) {
  .chatus-modal {
    width: 90%;
  }
  .chatus-input-container {
    flex-direction: column;
  }
  .chatus-input-container .name,
  .chatus-input-container .email_con {
    width: 100%;
  }
}
