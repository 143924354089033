.label {
  font-family: AgrandirBold !important;
  font-size: small;
  margin-left: 1.5rem !important;
}

.bank-label-d {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 93% !important;
}

.others-p {
  color: blue !important;
  font-family: Agrandir !important;
  cursor: pointer !important;
  /* font-size: medium !important; */
}

h4 {
  font-family: AgrandirBold !important;
  margin-left: 0.9rem !important;
}

.add-details-btn-ctrl {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
  font-family: AgrandirBold !important;
}

.add-details-btn {
  width: 100%;
  margin: auto;
}

.select-bank {
  width: 90% !important;
  margin-left: 3% !important;
}

@media screen and (max-width: 700px) {
  .label {
    margin-left: 0.8rem !important;
  }

  .add-account-btn-ctrl {
    margin-top: 10%;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
  }
  .add-account-btn {
    width: 100%;
    margin: auto;
  }

  .form-outer {
    width: 100% !important;
  }

  .select-bank {
    width: 95% !important;
  }
}

.form-outer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100% !important;
  height: 100% !important;
  padding: 2% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  /* margin-left: -2rem !important; */
  border: 1px solid #000 !important;
  margin-top: -1.5rem !important;
  border-radius: 2% !important;
}

.formGridCheckbox {
  margin-left: 3% !important;
}

.header {
  margin: 3%;
  font-family: AgrandirBold !important;
}

.text-area {
  width: 90%;
  margin-left: 3%;
  font-family: AgrandirBold !important;
}

.submit-details-btn {
  width: 15% !important;
  margin-top: 0.5% !important;
  margin-left: 3%;
  padding: 1%;
  font-family: AgrandirBold !important;
}

@media screen and (max-width: 700px) {
  .form-outer {
    margin-top: -1rem !important;
    width: 100% !important;
  }

  .submit-details-btn {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
