.mainDiv {

  padding-top: 50px;
  margin-top: -35px;
}

.securedContainer {
  display: flex;
  align-items: center; 
  justify-content: center;
  margin: 0;
  white-space: nowrap; /* Prevent text wrapping */
}

.securedContainer img {
  margin-right: -7px; 
  margin-bottom: 2px;/* Adjust the margin to reduce space */
}

.securedContainer p {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 300;
  margin: 0;
}

.securedContainer p span {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgb(8, 46, 136);
}
@media only screen and (max-width: 768px) {
 
  .securedContainer p {
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 350;
    margin: 0;
  }
  .securedContainer p span {
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 350;
    color: rgb(8, 46, 136);
  }
}