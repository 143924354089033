.camera_field_wrapper{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left:0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 15;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.camera_close_btn_wrapper{
  display: flex;
  justify-content: center;
}

.camera_close_btn_wrapper button{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px white solid;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.camera_close_btn_wrapper button img{
  width: 20px;
  height: 20px;
}

.video_recorder{
  width: 640px;
  height: 480px;
}

.camera_capture_buttons{
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.camera_capture_buttons button {
  width: 150px !important;
}

.start_selfie{
  background-color: #329521 !important;
  color: white !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500;
  border-radius: 5px !important;
  border: none !important;
}

.capture_selfie{
  background-color: #329521 !important;
  color: white !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500;
  border-radius: 5px !important;
  border: none !important;
}

@media screen and (max-width: 500px) {
  .video_recorder{
    width: 300px;
    height: 300px;
  }
}
