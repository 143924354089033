.merchants-container {
  display: flex;
  /* gap: 10px; */
  align-items: center;
  padding: 10px;
  background: #fff;
  border-radius: 20px;
}
.info-details {
  display: flex;
  flex-direction: column;
  /* background-color: rgb(255, 0, 153); */
}

.verified h4 {
  color: rgb(196, 192, 192);
  font-family: AgrandirBold;
  font-size: 16px;
  padding: 0 !important;
  margin: 0 !important;
}
.verified img {
  /* height: 40px; */
  width: 30px;
}
.apple-logo {
  /* height: 40px; */
  width: 25px;
}

.merchant-logo {
  width: 100px;
  /* flex-grow: 1; */
}

.logo-1 {
  width: 100%;
}
/* .mer_logos {
  width: 30px;
} */
@media screen and (max-width: 620px) {
  /* .merchant-logo .logo-1 {
    height: 20px;
    width: 20px;
  }
  .merchant-logo .logo-2 {
    height: 20px;
    width: 60px;
  } */
}
@media (max-width: 930px) and (min-width: 769px) {
  .merchants-container {
    flex-direction: column;
  }
  .merchant-logo {
    display: none;
  }
}
/* @media screen and (min-); */
