@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#transfer-interface {
  margin: 40px auto 0 auto;
  width: 600px;
  color: black;
  background-color: #0f3fb2;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  border-radius: 15px;
}

.transfer-header {
  padding: 20px 10px;
  background-color: #0f3fb2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.transfer-header button {
  align-self: flex-start;
  background-color: transparent;
  width: auto;
  border: none;
  outline: none;
}

.transfer-header h1 {
  color: white;
  font-size: 20px;
  margin: 0;
}

.not-needed {
  width: 30px;
  height: 30px;
  opacity: 0;
}

.transfer-body {
  padding: 20px;
  background-color: white;
  min-height: 400px;
  border-radius: 15px;
}

.transfer-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.transfer-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.transfer-option img {
  width: 35px;
}

.transfer-option > div {
  width: 100%;
}

.option-link {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #0f3fb2;
  text-decoration: none !important;
  font-family: "Poppins", sans-serif !important;
}

.option-text {
  font-size: 10px !important;
  font-family: "Poppins", sans-serif !important;
  color: #887c7c;
  padding: 0 !important;
}

.option-extra {
  display: flex;
  align-items: center;
  gap: 20px;
}

.option-label {
  background-color: #dbd8e8;
  padding: 3px 2px;
  color: #0f3fb2;
  font-size: 10px;
  font-family: "Poppins", sans-serif !important;
}

/* Beneficiaries section */

.benefeciaries-wrapper-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0f3fb2 !important;
}

.benefeciaries-wrapper-head > h2 {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.benefeciaries-wrapper-head > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.benefeciaries-wrapper-head > div > button {
  width: auto;
  height: auto;
  padding: 2px !important;
  font-weight: 600;
  background-color: transparent;
  border: none !important;
  outline: none !important;
}

.benefeciaries-wrapper-head > h2 {
  font-family: "Poppins", sans-serif !important;
}

.beneficiary-search {
  color: #745e5e !important;
}

.all-beneficiary-btn {
  color: #0f3fb2;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
}

/* Transfer Information section */

.transfer-card-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 60px;
}

.transfer-card-content {
  background: url("../../assets/images/card-bg.jfif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 180px;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white !important;
}

.transfer-account-name {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 600;
}

.transfer-card-content > .center-text {
  font-family: "Poppins", sans-serif !important;
  text-align: center;
  padding: 0 !important;
}

.transfer-card-content p {
  font-family: "Poppins", sans-serif !important;
  padding: 0 !important;
}

.slider-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
}

.slider-wrapper > p {
  font-size: 10px !important;
  padding: 0 !important;
}

.transfer-cash-back {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.transfer-cash-back > button {
  width: auto;
  height: auto;
  background-color: transparent;
  padding: 2px !important;
  border: none;
  outline: none;
}

.transfer-form-wrapper {
  padding: 20px 60px;
}

.transfer-receiver-email,
.transfer-receiver,
.transfer-receiver-amount {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 15px;
}

.transfer-receiver-email > label,
.transfer-receiver > label,
.transfer-receiver-amount > label {
  color: #0f3fb2;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  padding: 0;
  border: none;
  font-weight: 500;
}

.transfer-receiver-email > input,
.transfer-receiver > input,
.transfer-receiver-amount > input {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #7f74aeb2;
  color: black;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  width: 100%;
  font-size: 12px !important;
  font-weight: 500;
}

.transfer-receiver-email > input::placeholder,
.transfer-receiver > input::placeholder,
.transfer-receiver-amount > input::placeholder {
  font-size: 12px !important;
  color: #dcdfe3 !important;
}

.transfer-send-btn {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f3fb2;
  color: white;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.transfer-send-btn.loading {
  background-color: #aaa; /* Change to a different color to show it's loading */
}

.transfer-send-btn:disabled {
  background-color: #ccc; /* Disable color */
}


.transfer-payment-component {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.otp_section .otp-input {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  width: 55% !important;
  display: flex;
  align-items: center;
  padding: 2% !important;
  color: white;
  background-color: #0f3fb2 !important;
}

.otp_section .otp-input::placeholder {
  font-family: "Poppins", sans-serif !important;
  color: white !important;
  font-size: 12px !important;
}

.otp_section .color-link {
  color: #0f3fb2;
}

.otp_section .otp-resend-msg {
  margin: 30px auto;
}

.otp_section .auth-btn {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  background-color: #082e88 !important;
  color: #fff;
  width: 40%;
  cursor: pointer;
}

.otp_section .transfer-cancel-payment {
  margin: auto !important;
  width: 250px !important;
  background-color: #dedada !important;
  color: black !important;
  margin: 10px 0;
}

.no_transaction-p{
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  color: #0f3fb2 !important;
  padding: 0 !important;
}

@media screen and (max-width: 620px) {
  #transfer-interface {
    margin: 20px auto 0 auto !important;
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }

  .transfer-form-wrapper {
    padding: 10px 5px;
  }

  .transfer-card-wrapper {
    padding: 0 5px;
  }
}
