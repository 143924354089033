@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

section#verify_email{
 padding: 30px 80px;
}

.logo-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.2em;
  padding: 1%;
}

.signup-otp-logo {
  width: 15%;
}

.signup-otp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  width: 510px;
  margin-top: 5%;
  padding: 60px 80px !important;
  border: 1px solid #000;
  border-radius: 10px !important;
}

.signup-otp-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  /* border: 1px solid #000; */
}

.verify-message {
  color: #082e88;
  padding: 0 !important;
  font-size: 20px !important;
  font-weight: 700;
}

.signup-otp-row span{
  padding: 0 !important;
  font-family: Agrandir-Regular !important;
  text-align: center;
  color: black !important;
  font-size: 16px !important;
  width: 400px !important;
}

.otp-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  gap: 10px;
  width: 60%;
  margin-top: 0.5em;
  /* border: 1px solid #000; */
}

.otp-input-value {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid black;
  border-radius: 0%;
  width: 18% !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700;
  text-align: center;
  font-size: 36px !important;
  line-height: 0.5 !important;
  outline: none;
}

.code-notify {
  color: black !important;
  padding: 0 !important;
  font-family: Agrandir-Regular !important;
  font-size: 16px !important;
}

.code-notify button {
  color: #082e88 !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  width: unset !important;
}

button.verify-btn {
  color: white !important;
  background-color: #2962f2 !important;
  border: none !important;
  padding: 15px 0 !important;
  border-radius: 10px !important;
  width: 100% !important;
}

button.verify-btn:disabled {
  background-color: #a0c4ff; /* Faded blue color when disabled */
  cursor: not-allowed;
}

@media (max-width: 620px) {
  .logo-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.2em;
    padding: 1%;
  }

  .signup-otp-logo {
    width: 70% !important;
  }

  .signup-otp-container {
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
    margin-top: 5rem !important;
  }

  .otp-input-container {
    width: 80%;
  }
}
