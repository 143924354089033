@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* *{
  color: unset;
} */

body {
  padding: 0;
}

.Toastify__toast-body > div:last-child {
  color: black !important;
}

.Toastify__close-button {
  width: unset !important;
}

#singup-section {
  height: 100svh;
  width: 100%;
  /* background: url("../../assets/images/signup-bg.png"); */
  background: url("../../assets/images/login-bcg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.signup-section-container {
  display: flex;
  height: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 35px;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1%;
  height: 100%;
}

.signup-man-container {
  display: flex;
  justify-content: center;
}

p.buddy-text {
  font-family: "Poppins", sans-serif !important;
  color: white;
  font-size: 22px !important;
  padding: 0 !important;
}

.buddy-text strong {
  font-size: 32px;
  font-weight: 700;
}

.signup-man {
  height: 400px;
}

.main-signup-form {
  width: 100%;
  height: 100%;
  max-height: 852px;
  padding: 20px 0;
  display: flex;
  justify-content: end;
}

.signup-form-container {
  display: flex;
  flex-direction: column;
  border-radius: 30px !important;
  padding: 0 !important;
  margin: 0;
  width: 393px;
  min-height: 100%;
  border: none !important;
  /* background-color: #0b0230; */
  position: relative;
  overflow: hidden;
}

.ellipse-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.ellipse-right {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.signup-form {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px !important;
  height: 100%;
  background-color: white;
  border-radius: 30px !important;
  animation: slideInFromBottom 1s ease-out forwards;
}

.signup-steps-container {
  overflow-y: hidden;
  position: relative;
  z-index: 2;
}

.signup-footer {
  margin: auto auto 0 auto;
  display: flex;
  align-items: center;
}

#signup-first-step {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  padding: 0 !important;
}

#signup-second-step {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding: 0 !important;
}

#signup-first-step::-webkit-scrollbar,
#signup-second-step::-webkit-scrollbar {
  display: none;
}

.input-image-container {
  width: 25px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.small-icon {
  height: 25px;
}

.register-title-container p {
  color: #0b0230;
  font-family: "Poppins", sans-serif !important;
  padding: 0 !important;
}

.register-title-container .register-title {
  font-size: 20px !important;
  font-weight: 600;
}

.register-title-container .register-text {
  font-size: 12px !important;
  font-weight: 600;
}

.chat-button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #1c98f7;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  align-self: self-end;
}

.error-message {
  color: #db4141;
  margin-top: 0.5em !important;
  padding: 0;
  font-family: AgrandirRegular;
}

.checkbox-container {
  display: flex !important;
  flex-direction: row !important;
  /* border: 1px solid #000 !important; */
  background-color: #d3d3d3 !important;
  border-radius: 5px !important;
  flex-direction: column !important;
}

.checkbox-condition {
  width: fit-content !important;
}

.condition-text {
  padding-left: 0.5% !important;
  width: fit-content !important;
  margin-left: 1.5rem !important;
  margin-top: -1.5rem !important;
}

.text-inner {
  /* border: 1px solid #000 !important; */
  width: 100% !important;
  font-family: AgrandirBold !important;
  font-size: 9px !important;
  color: #fff !important;
  text-align: center !important;
}

h3 {
  margin-left: -13px !important;
}

.labels {
  margin-left: 0 !important;
  font-family: AgrandirRegular;
}

.form-input {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
  background-color: #d9d9d9;
}

.form-input input {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  color: #0b0230 !important;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.form-input input::placeholder {
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #b6b4be !important;
}

.gender-select {
  font-family: "Montserrat", sans-serif !important;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #0b0230;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none !important;
}

option {
  color: #0b0230 !important;
}

.signup-proceed-btn,
.signup-btn {
  font-family: "Montserrat", sans-serif !important;
  padding: 10px 0;
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f49b09eb;
  color: white;
  transition: ease-in-out;
  outline: none;
  border: none;
}

.signup-cancel-btn {
  font-family: "Montserrat", sans-serif !important;
  padding: 10px 0;
  width: 100%;
  font-style: italic;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #f49b09eb;
  background-color: transparent;
}

.signup-cancel-btn:hover {
  color: white;
  background-color: #0b0230;
}

.signup-proceed-btn:hover,
.signup-btn:hover {
  background-color: #0b0230;
}

.signup-footer p{
  /* font-family: "Poppins", sans-serif !important; */
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px !important;
  color: #F49B09EB !important;
  font-style: italic !important;
  font-weight: 500 !important;
  padding: 0 !important;
}

.signup-footer p .option-verify{
  color: #0B0230 !important;
}

.password_btn {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  color: #0b0230 !important;
  border-radius: 0 !important;
  width: 20% !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

svg.eye-icon {
  width: 40px;
  margin-top: 0 !important;
  margin-left: 0;
  fill: #0b0230;
}

.lufga-text {
  font-size: 12px !important;
  font-style: italic;
  font-weight: 600;
  color: #0b0230;
  padding: 0 !important;
}

.lufga-text > span {
  color: #f49b09eb !important;
}

a.link-to-privacy {
  text-decoration: none !important;
  color: #f49b09eb !important;
}

.privacy-condition {
  display: flex;
  gap: 12px;
  align-items: start;
}

.privacy-condition p {
  font-size: 12px !important;
  font-style: italic;
  font-weight: 600;
  color: #0b0230;
  padding: 0 !important;
}

.signup-extra-options p {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  color: #f49b09eb !important;
  text-align: center;
  padding: 0 !important;
}

a.option-signin,
a.option-verify {
  color: #0b0230 !important;
  text-decoration: none !important;
}

.phone-input {
  margin: 0 !important;
  padding: 0% !important;
  width: 100% !important;
}

.react-datepicker__input-container {
  width: 100% !important;
  padding: 0.375rem !important;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem !important;
}

.dob {
  width: 100% !important;
  font-family: AgrandirBold !important;
  font-size: small !important;
  border: none !important;
}

.dob:focus-visible {
  outline: none !important;
}

.signup-btn {
  margin-top: 0.9em !important;
  width: 100% !important;
  padding: 0.5em !important;
  font-family: AgrandirRegular;
}

.password_tracker{
  background-color: #D9D9D9;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
}

.password_tracker > div{
  display: flex;
  gap: 3px;
  align-items: center;
}

.validated{
  color: green
}

.not-validated{
  color: #b6b4be;
}

.password_p{
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  padding: 0 !important;
}

@media screen and (max-width: 900px) {
  .signup-section-container {
    padding: 0 10px;
  }

  .chat-button {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .signup-section-container {
    padding: 0;
  }

  .main-signup-form {
    width: 100%;
    height: 100%;
    max-height: unset;
    padding: 0 !important;
    justify-content: center;
    /* justify-content: unset; */
  }

  .signup-section-container .logo-container {
    display: none !important;
  }

  .signup-form-container {
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0;
    width: 100%;
    min-height: 100%;
    border: none !important;
    background-color: #0b0230;
    position: relative;
    overflow: hidden;
  }

  .signup-form {
    border-radius: 0 !important;
    padding: 130px 20px 20px 20px !important;
  }
}

@media (max-width: 620px) {
  .logo-container {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    justify-content: center;
  }

  .signup-logo {
    width: 100% !important;
  }

  .signup-container {
    width: 100% !important;
    /* height: 100% !important; */
  }
}
