.dashboard_container_wrapper {
  overflow: hidden;
  max-width: 1400px;
  margin: auto;
}
.dashboard_body_container_wrapper {
  margin-left: 218px;
  padding-top: 1rem;
}
.dashboard_header_container_wrapper {
  padding: 0 1rem;
  position: relative;
  /* z-index: 100; */
}
.dashboard_body_content_wrapper {
  padding: 0 1.5rem;
}
.dashboard {
  display: flex;
  /* width: 100%; */
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #20192c;
  padding: 1rem;
  gap: 1rem;
  border-radius: 20px;
  position: relative;
}
.dashboard-right {
  padding: 0 1rem;
  width: 60%;
}
.dashboard-left {
  max-width: 40%;
  padding: 1rem;
}
.dashboard-transaction-table-container {
  margin-left: 0rem;
  padding: 0;
  width: 100% !important;
  margin-top: 1rem;
}
.nav-con {
  width: 20% !important;
}

.vector-blop1 {
  position: absolute;
  max-width: 350px;
  top: -15%;
  left: -12%;
}
.vector-blop2 {
  position: absolute;
  max-width: 350px;
  top: -25%;
  right: -10%;
}
.vector-blop3 {
  position: absolute;
  max-width: 350px;
  bottom: 0%;
  right: -10%;
  z-index: 0;
}

.no-data {
  /* border: 1px solid #000 !important; */
  font-family: AgrandirBold !important;
  font-size: large !important;
  padding: 2% !important;
  margin-top: 5% !important;
  margin-bottom: 5% !important;
  margin-left: 12% !important;
  text-align: center !important;
  color: #fff !important;
}

.statement {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.default-card-holder {
  padding: 0;
  margin-bottom: 1rem;
  width: 100%;
}
.mchnt-con .mchnt-con-div {
  width: 100%;
}
/* .card-header {
  font-family: AgrandirBold;
  font-size: large !important;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
} */

@media screen and (max-width: 620px) {
  .dashboard-right {
    padding: 0;
  }
  .dashboard-left {
    padding: 0;
  }
}
@media screen and (max-width: 769px) {
  .dashboard {
    flex-direction: column;
  }
  .dashboard_body_container_wrapper {
    margin-left: 0;
  }
  .dashboard-right {
    width: 100%;
  }
  .dashboard-left {
    max-width: 100%;
  }
}
@media screen and (max-width: 1100px) {
}
@media (max-width: 1025px) and (min-width: 769px) {
  .dashboard-right {
    padding: 0;
  }
  .dashboard-left {
    padding: 1rem 0;
  }
}
/* @media screen and (max-width: 620px) {
  .dashboard_body_content_wrapper {
    padding: 0;
  }
  .dashboard {
    margin-top: 0rem;
  }
  .dashboard-right {
    width: 100%;
    padding: 0;
  }
  .dashboard-left {
    max-width: 100%;
  }
  .dashboard-transaction-table-container {
    margin-left: 0rem !important;
    width: 100% !important;
    padding: 0;
  }

  .default-card-holder {
    margin: 0;
  }
  .card-header {
    margin-top: 80px;
  }
  .card-holder {
    margin: 0 !important;
    margin-top: -3rem !important;
  }
  .dashboard-transaction-table-container {
    margin-left: 4.5rem;
  }
  .no-data {
    font-family: AgrandirBold !important;
    font-size: large !important;
    padding: 2% !important;
    margin-top: 5% !important;
    margin-bottom: 5% !important;
    margin-left: auto !important;
    text-align: center !important;
    color: #fff !important;
  }

  .mchnt-con {
    margin-top: 2.5rem;
  }

  .statement {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 3rem !important;
    margin-top: 1rem !important;
  }

  .card-holder {
    margin-left: -2rem;
    margin-top: -2.5rem;
  }
  .vector-blop1 {
    width: 150px;
    top: 0%;
    left: -10%;
  }
} */

/* @media screen and (min-width: 620px) {
  .default-card-holder {
    transform: translateX(2rem);
  }
  .dashboard-right {
    max-width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .dashboard_body_container_wrapper {
    margin-left: 0;
    padding-top: 1rem;
  }
} */
/* @media (max-width: 1024px) and (min-width: 621px) {
  .spending {
    margin-left: 16px;
  }
  .dashboard-transaction-table-container {
    margin-left: 1rem;
  }
  .statement {
    margin-left: -1rem;
  }

  .no-data {
    font-family: AgrandirBold !important;
    font-size: large !important;
    padding: 2% !important;
    margin-top: 5% !important;
    margin-bottom: 5% !important;
    margin-left: auto !important;
    text-align: center !important;
    color: #fff !important;
  }
} */
